import React, { createContext, useState, useContext, useEffect } from 'react';
import { useApi } from '../utils/api';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 > Date.now()) {
            // Token 还未过期，获取用户信息
            const userInfo = await api.get('/api/me');
            setUser(userInfo);
          } else {
            // Token 已过期，清除本地存储
            localStorage.removeItem('token');
          }
        } catch (error) {
          console.error('Failed to fetch user data:', error);
          localStorage.removeItem('token');
        }
      }
      setIsLoading(false);
    };

    initializeAuth();
  }, []); // 移除 api 依赖

  const login = async (email, password) => {
    setIsLoading(true);
    try {
      const response = await api.post('/api/login', { email, password });
      if (response.access_token) {
        localStorage.setItem('token', response.access_token);
        const userInfo = await api.get('/api/me');
        setUser(userInfo);
        return userInfo;
      } else {
        throw new Error('Login failed: No access token received');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  const value = {
    user,
    login,
    logout,
    isLoading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);