import React, { useState, useEffect } from 'react';
import { useApi } from '../../utils/api';
import { Line, Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const FlashcardStats = () => {
    const [stats, setStats] = useState(null);
    const { get } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0); // 页面加载时滚动到顶部
    }, []);

    useEffect(() => {
        const fetchStats = async () => {
            setIsLoading(true);
            try {
                const response = await get('/api/flashcards/stats');
                setStats(response);
            } catch (error) {
                console.error('Error fetching flashcard stats:', error);
                setError('获取统计信息时出错，请稍后再试。');
            } finally {
                setIsLoading(false);
            }
        };

        fetchStats();
    }, [get]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center p-4">
                <svg
                    className="animate-spin h-6 w-6 text-gray-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                </svg>
                <span className="ml-2 text-gray-600">加载中...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center p-4">
                <span className="text-red-500">{error}</span>
            </div>
        );
    }

    // 示例数据，您需要根据实际的数据结构调整
    const totalCards = stats.total_cards;
    const dueCards = stats.due_cards;
    const reviewedCards = stats.reviewed_cards;
    const newCards = stats.new_cards; // 新增的
    const learningCards = stats.learning_cards; // 正在学习的
    const graduatedCards = stats.graduated_cards; // 已掌握的

    // 准备饼图数据
    const pieData = {
        labels: ['新卡片', '学习中', '已掌握'],
        datasets: [
            {
                data: [newCards, learningCards, graduatedCards],
                backgroundColor: ['#FF6384', '#36A2EB', '#4CAF50'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#4CAF50'],
            },
        ],
    };

    // 准备折线图数据，例如复习进度
    const lineData = {
        labels: stats.review_history.dates, // 日期数组
        datasets: [
            {
                label: '复习卡片数',
                data: stats.review_history.counts, // 对应日期的复习数量
                fill: false,
                borderColor: '#4CAF50',
            },
        ],
    };

    return (
        <div className="w-full p-4">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">复习统计</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* 卡片统计 */}
                <div className="bg-white rounded-xl shadow-lg p-6">
                    <h3 className="text-xl font-semibold mb-4">卡片概览</h3>
                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <span>总卡片数：</span>
                            <span>{totalCards}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>待复习卡片：</span>
                            <span>{dueCards}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>已复习卡片：</span>
                            <span>{reviewedCards}</span>
                        </div>
                        <div>
                            <span>复习进度：</span>
                            <div className="w-full bg-gray-200 rounded-full h-4 mt-1">
                                <div
                                    className="bg-blue-600 h-4 rounded-full"
                                    style={{
                                        width: `${(reviewedCards / totalCards) * 100}%`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 饼图展示 */}
                <div className="bg-white rounded-xl shadow-lg p-6">
                    <h3 className="text-xl font-semibold mb-4">卡片分布</h3>
                    <Pie data={pieData} />
                </div>

                {/* 折线图展示 */}
                <div className="bg-white rounded-xl shadow-lg p-6 md:col-span-2">
                    <h3 className="text-xl font-semibold mb-4">复习历史</h3>
                    <Line data={lineData} />
                </div>
            </div>
        </div>
    );
};

export default FlashcardStats;