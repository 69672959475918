import React from 'react';

const Loading = ({ text = "加载中...", size = 5, className = "" }) => {
  return (
    <div className={`flex justify-center items-center mt-4 ${className}`}>
      <svg 
        className={`animate-spin h-${size} w-${size} text-gray-600`} 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" 
        viewBox="0 0 24 24"
      >
        <circle 
          className="opacity-25" 
          cx="12" 
          cy="12" 
          r="10" 
          stroke="currentColor" 
          strokeWidth="4"
        ></circle>
        <path 
          className="opacity-75" 
          fill="currentColor" 
          d="M4 12a8 8 0 018-8v8H4z"
        ></path>
      </svg>
      {text && <span className="ml-2 text-gray-600">{text}</span>}
    </div>
  );
};

export default Loading;