import React, { useState, useEffect, createContext, useContext } from 'react';
import { AlertCircle, CheckCircle, XCircle, X } from 'lucide-react';

// 定义对话框类型
const DialogTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
};

// 定义对话框图标
const DialogIcons = {
    [DialogTypes.SUCCESS]: CheckCircle,
    [DialogTypes.ERROR]: XCircle,
    [DialogTypes.INFO]: AlertCircle,
    [DialogTypes.WARNING]: AlertCircle
};

// 定义对话框颜色
const DialogColors = {
    [DialogTypes.SUCCESS]: 'bg-green-50 text-green-700',
    [DialogTypes.ERROR]: 'bg-red-50 text-red-700',
    [DialogTypes.INFO]: 'bg-blue-50 text-blue-700',
    [DialogTypes.WARNING]: 'bg-yellow-50 text-yellow-700'
};

// 模态对话框组件
const ModalDialog = ({ title, message, type = DialogTypes.INFO, onClose }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setIsVisible(false);
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    if (!isVisible) return null;

    const Icon = DialogIcons[type];

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className={`bg-white rounded-lg shadow-xl max-w-md w-full ${DialogColors[type]}`}>
                <div className="flex items-center justify-between px-6 py-4 border-b">
                    <div className="flex items-center space-x-3">
                        <Icon className={`w-6 h-6 ${type === DialogTypes.WARNING ? 'text-yellow-500' : ''}`} />
                        <h3 className="text-lg font-semibold">{title}</h3>
                    </div>
                    <button
                        onClick={() => { setIsVisible(false); onClose(); }}
                        className="text-gray-400 hover:text-gray-600 p-2"
                    >
                        <X className="w-5 h-5" />
                    </button>
                </div>
                <div className="px-6 py-4">
                    <p className="text-sm" style={{ padding: '0.5rem' }}>{message}</p>
                </div>
            </div>
        </div>
    );
};

// 创建 Dialog 上下文
const DialogContext = createContext(null);

// Dialog Provider 组件
export const DialogProvider = ({ children }) => {
    const [dialogs, setDialogs] = useState([]);

    const showDialog = (title, message, type) => {
        const id = Date.now();
        setDialogs(prev => [...prev, { id, title, message, type }]);
    };

    const closeDialog = (id) => {
        setDialogs(prev => prev.filter(dialog => dialog.id !== id));
    };

    return (
        <DialogContext.Provider value={{ showDialog, closeDialog }}>
            {children}
            {dialogs.map(({ id, title, message, type }) => (
                <ModalDialog
                    key={id}
                    title={title}
                    message={message}
                    type={type}
                    onClose={() => closeDialog(id)}
                />
            ))}
        </DialogContext.Provider>
    );
};

// 自定义 Hook 用于使用对话框
export const useDialog = () => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error('useDialog must be used within a DialogProvider');
    }
    
    const { showDialog } = context;

    // 添加便捷函数
    const showSuccessDialog = (title, message) => showDialog(title, message, DialogTypes.SUCCESS);
    const showErrorDialog = (title, message) => showDialog(title, message, DialogTypes.ERROR);
    const showInfoDialog = (title, message) => showDialog(title, message, DialogTypes.INFO);
    const showWarningDialog = (title, message) => showDialog(title, message, DialogTypes.WARNING);

    return {
        ...context,
        showSuccessDialog,
        showErrorDialog,
        showInfoDialog,
        showWarningDialog
    };
};

// 导出 DialogTypes 以便在其他组件中使用
export { DialogTypes };