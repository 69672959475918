import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { PlusCircle, BookOpen } from 'lucide-react';
import ArticleEditor from '@/components/ArticleEditor';
import { useApi } from '@/utils/api';

const ArticlesPage = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [articles, setArticles] = useState([]);
    const [isCreatingArticle, setIsCreatingArticle] = useState(false);
    const [currentArticle, setCurrentArticle] = useState(null);

    useEffect(() => {
        fetchArticles();
    }, []);

    const fetchArticles = async () => {
        try {
            const response = await api.get(`/api/articles`);
            console.log('Full response:', response);
            console.log('Response type:', typeof response);
            if (Array.isArray(response)) {
                setArticles(response);
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };
    
    const handleCreateArticle = () => {
        setCurrentArticle({ title: '', content: [] });
        setIsCreatingArticle(true);
        navigate('/articles/new');
    };

    const handleSaveArticle = async (articleData) => {
        try {
            const method = articleData._id ? 'PUT' : 'POST';
            const url = articleData._id ? `/api/articles/${articleData._id}` : '/api/articles';
            const response = await api[method](url, articleData);

            if (response && !response.error) {
                fetchArticles();
                setIsCreatingArticle(false);
            } else {
                console.error('Failed to save article');
            }
        } catch (error) {
            console.error('Error saving article:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await api.delete(`/api/articles/${id}`);
            if (response && !response.error) {
                fetchArticles();
            } else {
                console.error(`Failed to delete article`);
            }
        } catch (error) {
            console.error(`Error deleting article:`, error);
        }
    };

    const handleCancelEdit = () => {
        setIsCreatingArticle(false);
    };

    const handleRead = (id) => {
        navigate(`/articles/${id}`);
    };

    const handleEdit = (article) => {
        navigate(`/articles/${article._id}/edit`);
    };

    return (
        <div className="container mx-auto">
            <div className="mb-4">
                <Button onClick={handleCreateArticle}>
                    <PlusCircle className="mr-2 h-4 w-4" /> 创建文章
                </Button>
            </div>
            <ul className="space-y-2">
                {articles.length === 0 ? (
                    <li key="no-articles">没有文章</li>
                ) : (
                    articles.map((article) => (
                        <li key={article._id} className="list-item">
                            <span>{article.title}</span>
                            <div className="buttons">
                                <Button variant="ghost" size="sm" onClick={() => handleRead(article._id)}>
                                    <BookOpen className="mr-1 h-4 w-4" /> 阅读
                                </Button>
                                <Button variant="ghost" size="sm" onClick={() => handleEdit(article)}>
                                    编辑
                                </Button>
                                <Button variant="ghost" size="sm" onClick={() => handleDelete(article._id)}>删除</Button>
                            </div>
                        </li>
                    ))
                )}
            </ul>
            {isCreatingArticle && (
                <ArticleEditor
                    article={currentArticle}
                    onSave={handleSaveArticle}
                    onCancel={handleCancelEdit}
                />
            )}
        </div>
    );
};

export default ArticlesPage;