import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';

// 导入认证相关组件
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/RegisterForm';
import EmailVerificationForm from './components/auth/EmailVerificationForm';
import PasswordResetRequestForm from './components/auth/PasswordResetRequestForm';
import PasswordResetForm from './components/auth/PasswordResetForm';

// 导入其他页面组件
import ArticlesPage from './components/ArticlesPage';
import PDFsPage from './components/PDFsPage';
import EPUBsPage from './components/EPUBsPage';
import ArticleView from './components/ArticleView';
import PDFViewer from './components/PDFViewer';
import EPUBViewer from './components/EPUBViewer';
import EditArticlePage from './components/EditArticlePage';
import FlashcardReview from './components/FlashCards/FlashcardReview';
import FlashcardList from './components/FlashCards/FlashcardList';
import FlashcardStats from './components/FlashCards/FlashcardStats';
import FlashcardCreation from './components/FlashCards/FlashCardCreation';
import { DialogProvider } from '@/utils/DialogComponent';

function App() {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        document.documentElement.style.backgroundColor = 'white';
    }, []);

    return (
        <DialogProvider>
            <Router>
                <AuthProvider>
                    <Routes>
                        {/* 公共路由 */}
                        <Route path="/login" element={<LoginForm />} />
                        <Route path="/register" element={<RegisterForm />} />
                        <Route path="/verify-email" element={<EmailVerificationForm />} />
                        <Route path="/password-reset-request" element={<PasswordResetRequestForm />} />
                        <Route path="/password-reset" element={<PasswordResetForm />} />

                        {/* 使用 Layout 包装的受保护路由 */}
                        <Route path="/" element={
                            <ProtectedRoute>
                                <Layout>
                                    <ArticlesPage />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/articles" element={
                            <ProtectedRoute>
                                <Layout>
                                    <ArticlesPage />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/articles/:id" element={
                            <ProtectedRoute>
                                <Layout>
                                    <ArticleView />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/articles/new" element={
                            <ProtectedRoute>
                                <Layout>
                                    <EditArticlePage />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/articles/:id/edit" element={
                            <ProtectedRoute>
                                <Layout>
                                    <EditArticlePage />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/pdfs" element={
                            <ProtectedRoute>
                                <Layout>
                                    <PDFsPage />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/pdfs/:id" element={
                            <ProtectedRoute>
                                <Layout>
                                    <PDFViewer />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/epubs" element={
                            <ProtectedRoute>
                                <Layout>
                                    <EPUBsPage />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/epubs/:id" element={
                            <ProtectedRoute>
                                <Layout>
                                    <EPUBViewer />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/flashcards" element={
                            <ProtectedRoute>
                                <Layout>
                                    <FlashcardList />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/flashcards/review" element={
                            <ProtectedRoute>
                                <Layout noPadding>
                                    <FlashcardReview />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/flashcards/stats" element={
                            <ProtectedRoute>
                                <Layout noPadding>
                                    <FlashcardStats />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        <Route path="/flashcards/create" element={
                            <ProtectedRoute>
                                <Layout noPadding>
                                    <FlashcardCreation />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* 重定向未匹配的路由到首页 */}
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </AuthProvider>
            </Router>
        </DialogProvider>
    );
}

export default App;