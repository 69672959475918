import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Search } from 'lucide-react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { useApi } from '../../utils/api';
import { useDialog } from '../../utils/DialogComponent';

// 单词卡片组件，使用 React.memo 进行优化
const WordCard = React.memo(({ word, isSelected, onSelect }) => {
    const handleClick = useCallback(() => {
        onSelect(word.word);
    }, [word.word, onSelect]);

    const playAudio = useCallback((e, url) => {
        e.stopPropagation();
        const audio = new Audio(url);
        setTimeout(() => {
            audio.play();
        }, 100); // 异步延迟播放，避免阻塞
    }, []);

    return (
        <div
            className={`p-4 border rounded-md cursor-pointer overflow-hidden ${isSelected ? 'bg-blue-100' : ''
                }`}
            onMouseDown={handleClick} // 改为 onMouseDown 提升点击响应速度
        >
            <div className="flex items-center space-x-2">
                <h3 className="font-medium text-lg text-gray-900">{word.word}</h3>
                {word.word_info && word.word_info.soundmark && (
                    <>
                        {word.word_info.soundmark.uk && (
                            <span
                                className="text-sm text-gray-600 cursor-pointer"
                                onClick={(e) => playAudio(e, word.word_info.soundmark.uk[1])}
                            >
                                {word.word_info.soundmark.uk[0]}
                            </span>
                        )}
                        {word.word_info.soundmark.usa && (
                            <span
                                className="text-sm text-gray-600 cursor-pointer"
                                onClick={(e) => playAudio(e, word.word_info.soundmark.usa[1])}
                            >
                                {word.word_info.soundmark.usa[0]}
                            </span>
                        )}
                    </>
                )}
            </div>
            <p className="text-sm text-gray-600 mt-2 overflow-hidden text-ellipsis" style={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
            }}>
                {word.word_info ? word.word_info.meanings : ''}
            </p>
        </div>
    );
});

// 分页控件组件
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
        const pages = [];
        const maxPagesToShow = 5;
        let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
        let endPage = startPage + maxPagesToShow - 1;

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - maxPagesToShow + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return pages;
    };

    const pageNumbers = getPageNumbers();

    return (
        <div className="flex justify-center items-center space-x-2 mt-4">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 border rounded-md text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
                上一页
            </button>
            {pageNumbers.map(page => (
                <button
                    key={page}
                    onClick={() => onPageChange(page)}
                    className={`px-3 py-1 border rounded-md text-sm font-medium ${page === currentPage
                        ? 'bg-[#adadad] text-white'
                        : 'bg-gray-200 hover:bg-gray-300'
                        }`}
                >
                    {page}
                </button>
            ))}
            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-3 py-1 border rounded-md text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
                下一页
            </button>
        </div>
    );
};

const FlashcardCreation = () => {
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)), // 默认设置为一周前
            endDate: new Date(), // 结束日期为今天
            key: 'selection'
        }
    ]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [minQueryCount, setMinQueryCount] = useState(1);
    const [words, setWords] = useState([]);
    const [selectedWords, setSelectedWords] = useState(new Set());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { get, post } = useApi();
    const calendarRef = useRef(null);
    const { showSuccessDialog, showErrorDialog } = useDialog();
    const limit = 50; // 每页显示的单词数量

    useEffect(() => {
        window.scrollTo(0, 0); // 页面加载时滚动到顶部
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [calendarRef]);

    const fetchWords = useCallback(async (page = 1) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await get('/api/words/queried', {
                params: {
                    startDate: dateRange[0].startDate.toISOString(),
                    endDate: dateRange[0].endDate.toISOString(),
                    min_query_count: minQueryCount,
                    page,
                    limit
                }
            });
            setWords(response.words);
            setCurrentPage(response.page);
            setTotalPages(Math.ceil(response.total_count / limit));
        } catch (error) {
            console.error('Error fetching words:', error);
            setError('获取单词时出错，请稍后再试。');
        } finally {
            setIsLoading(false);
        }
    }, [dateRange, minQueryCount, get, limit]);

    useEffect(() => {
        // 初始加载第一页
        fetchWords(1);
    }, [fetchWords]);

    const handleSelectWord = useCallback((wordId) => {
        setSelectedWords(prev => {
            const newSelected = new Set(prev);
            if (newSelected.has(wordId)) {
                newSelected.delete(wordId);
            } else {
                newSelected.add(wordId);
            }
            return newSelected;
        });
    }, []);

    const handleSelectAll = () => {
        const newSelected = new Set(selectedWords);
        words.forEach(word => newSelected.add(word.word));
        setSelectedWords(newSelected);
    };

    const handleDeselectAll = () => {
        const newSelected = new Set(selectedWords);
        words.forEach(word => newSelected.delete(word.word));
        setSelectedWords(newSelected);
    };

    const handleInvertSelection = () => {
        setSelectedWords(prev => {
            const newSelected = new Set(prev);
            words.forEach(word => {
                if (newSelected.has(word.word)) {
                    newSelected.delete(word.word);
                } else {
                    newSelected.add(word.word);
                }
            });
            return newSelected;
        });
    };

    const handleCreateFlashcards = useCallback(async () => {
        if (selectedWords.size === 0) return;
        setIsLoading(true);
        setError(null);
        try {
            await post('/api/flashcards/create-batch', Array.from(selectedWords));
            showSuccessDialog('成功', '闪卡创建成功！');
            setSelectedWords(new Set());
        } catch (error) {
            console.error('Error creating flashcards:', error);
            showErrorDialog('创建闪卡失败', '创建闪卡时出错，请重试。');
        } finally {
            setIsLoading(false);
        }
    }, [post, selectedWords, showSuccessDialog, showErrorDialog]);

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        fetchWords(page);
    };

    const isAllSelected = useMemo(() => {
        return words.length > 0 && words.every(word => selectedWords.has(word.word));
    }, [words, selectedWords]);

    const isAnySelected = useMemo(() => selectedWords.size > 0, [selectedWords]);

    return (
        <div className="overflow-hidden flex flex-col min-h-screen max-w-full mx-auto bg-white rounded-lg shadow-md py-8 px-4">
            {/* 主内容区域，使用flex-grow确保占满剩余空间 */}
            <div className="flex-grow flex flex-col items-center space-y-4">
                <div className="flex items-center justify-center space-x-4 w-full">
                    {/* 日期选择按钮 */}
                    <div className="relative" ref={calendarRef}>
                        <button
                            onClick={() => setShowCalendar(prev => !prev)}
                            className="px-4 py-2 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {format(dateRange[0].startDate, 'yyyy-MM-dd')} 至 {format(dateRange[0].endDate, 'yyyy-MM-dd')}
                        </button>
                        {showCalendar && (
                            <div className="absolute z-10 mt-1">
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={item => setDateRange([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={dateRange}
                                    className="border rounded-md shadow-lg"
                                />
                            </div>
                        )}
                    </div>

                    {/* 最小查询次数输入框 */}
                    <div className="flex flex-col items-center">
                        <input
                            type="number"
                            value={minQueryCount}
                            onChange={(e) => setMinQueryCount(e.target.value)}
                            className="w-16 p-2 border rounded-md text-sm"
                            min="1"
                        />
                    </div>

                    {/* 查询单词按钮 */}
                    <button
                        onClick={() => fetchWords(1)}
                        className="px-4 py-2 border border-transparent rounded-md text-sm font-bold text-white bg-[#a7a7a7] hover:bg-[#8b8b8b] shadow-md hover:shadow-lg flex items-center space-x-2"
                        disabled={isLoading}
                    >
                        <Search className="w-4 h-4" />
                    </button>
                </div>

                <hr className="my-6 w-full" />

                {/* 选择和制作闪卡按钮 */}
                {words.length > 0 && (
                    <div className="flex items-center justify-center space-x-2">
                        <button
                            onClick={handleSelectAll}
                            className={`px-3 py-1 border rounded-md text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 ${isAllSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={isAllSelected}
                        >
                            全选
                        </button>
                        <button
                            onClick={handleDeselectAll}
                            className={`px-3 py-1 border rounded-md text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 ${!isAnySelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={!isAnySelected}
                        >
                            取消全选
                        </button>
                        <button
                            onClick={handleInvertSelection}
                            className="px-3 py-1 border rounded-md text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300"
                        >
                            反选
                        </button>
                        <button
                            onClick={handleCreateFlashcards}
                            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:bg-gray-500 disabled:cursor-not-allowed"
                            disabled={selectedWords.size === 0 || isLoading}
                        >
                            制卡
                        </button>
                    </div>
                )}

                {/* 加载和错误状态 */}
                {isLoading && (
                    <div className="flex justify-center items-center mt-4">
                        <svg className="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                        <span className="ml-2 text-gray-600">加载中...</span>
                    </div>
                )}

                {error && (
                    <div className="flex justify-center items-center mt-4">
                        <span className="text-red-500">{error}</span>
                    </div>
                )}

                {/* 查询结果展示区域 */}
                {!isLoading && !error && words.length > 0 && (
                    <div className="bg-white rounded-lg p-6 shadow-md w-full">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {words.map(word => (
                                <WordCard
                                    key={word.word}
                                    word={word}
                                    isSelected={selectedWords.has(word.word)}
                                    onSelect={handleSelectWord}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {!isLoading && !error && words.length === 0 && (
                    <div className="flex justify-center items-center mt-4">
                        <span className="text-gray-500">暂无数据，请调整查询条件后重新查询。</span>
                    </div>
                )}
            </div>

            {/* 分页控件始终在底部 */}
            {!isLoading && !error && words.length > 0 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            )}
        </div>
    );
};

export default FlashcardCreation;