import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';

const API_BASE_URL = process.env.REACT_APP_API_HOST ||  'https://tradehelper.site:8005' || 'http://192.168.0.100:8010';
console.log(`process.env.REACT_APP_API_HOST: ${process.env.REACT_APP_API_HOST}, API_BASE_URL: ${API_BASE_URL}`)

export const useApi = () => {
    const navigate = useNavigate();

    const handleUnauthorized = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login', { state: { message: '您的登录信息已过期，请重新登录。' } });
    }, [navigate]);

    const axiosInstance = useMemo(() => {
        const instance = axios.create({
            baseURL: API_BASE_URL,
            timeout: 300000, // 增加到5分钟
        });

        instance.interceptors.request.use((config) => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    if (decodedToken.exp * 1000 <= Date.now()) {
                        handleUnauthorized();
                        return Promise.reject('Session expired');
                    }
                    config.headers['Authorization'] = `Bearer ${token}`;
                } catch (error) {
                    console.error('Error decoding token:', error);
                    handleUnauthorized();
                    return Promise.reject('Invalid token');
                }
            }
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        instance.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response && error.response.status === 401) {
                handleUnauthorized();
            }
            return Promise.reject(error);
        });

        return instance;
    }, [handleUnauthorized]);

    const get = useCallback(async (url, config = {}) => {
        try {
            const response = await axiosInstance.get(url, {
                ...config,
                signal: config.signal
            });
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.error('GET request failed:', error);
            }
            throw error;
        }
    }, [axiosInstance]);

    const post = useCallback(async (url, data, config = {}) => {
        try {
            const response = await axiosInstance.post(url, data, config);
            return response.data;
        } catch (error) {
            console.error('POST request failed:', error);
            throw error;
        }
    }, [axiosInstance]);

    const put = useCallback(async (url, data, config = {}) => {
        try {
            const response = await axiosInstance.put(url, data, config);
            return response.data;
        } catch (error) {
            console.error('PUT request failed:', error);
            throw error;
        }
    }, [axiosInstance]);

    const del = useCallback(async (url, config = {}) => {
        try {
            const response = await axiosInstance.delete(url, config);
            return response.data;
        } catch (error) {
            console.error('DELETE request failed:', error);
            throw error;
        }
    }, [axiosInstance]);

    return { get, post, put, del };
};
