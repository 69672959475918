import React, { useState, useRef, useEffect, useLayoutEffect, useMemo, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FixedSizeList as List } from 'react-window';
import { useApi } from '../utils/api';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useLocation } from 'react-router-dom';
import WordExplanation from './WordExplanation';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.mjs',
    import.meta.url,
).toString();

function PDFViewer() {
    const [pdfData, setPdfData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [error, setError] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [pageWidth, setPageWidth] = useState();
    const [pageHeight, setPageHeight] = useState(800);
    const containerRef = useRef(null);
    const location = useLocation();
    const fileId = location.state?.fileId;
    const listRef = useRef(null);
    const { get } = useApi();

    const [wordExplanation, setWordExplanation] = useState({
        word: '',
        position: { x: 0, y: 0 },
        isVisible: false
    });
    const [wordCache, setWordCache] = useState({});
    const [showExplanation, setShowExplanation] = useState(false);
    const wordExplanationRef = useRef(null);

    useLayoutEffect(() => {
        const updatePageWidth = () => {
            if (containerRef.current) {
                const menuElement = document.querySelector('nav');
                const windowWidth = window.innerWidth;
                const menuStyle = getComputedStyle(menuElement);

                const isMenuHidden = menuStyle.display === 'none' || menuStyle.visibility === 'hidden';

                let mainWidth;
                if (isMenuHidden) {
                    mainWidth = windowWidth;
                    console.log("mainWidth1:", mainWidth);
                } else {
                    const menuWidth = menuElement.offsetWidth;
                    mainWidth = windowWidth - menuWidth;
                    console.log("mainWidth2:", mainWidth);
                }

                const containerWidth = Math.min(mainWidth, 1000);
                setPageWidth(containerWidth);
            }
        };

        const timeoutId = setTimeout(updatePageWidth, 100);
        const observer = new ResizeObserver(updatePageWidth);
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }
        window.addEventListener('resize', updatePageWidth);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('resize', updatePageWidth);
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        async function fetchPDF() {
            try {
                const response = await get(`/api/files/serve/${fileId}`, {
                    headers: {
                        'Range': 'bytes=0-',
                    },
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setLoadingProgress(percentCompleted);
                    }
                });

                setPdfData(response);
            } catch (error) {
                console.error('Error fetching PDF:', error);
                setError(error);
            }
        }

        fetchPDF();
    }, [fileId, get]);

    const pdfDataUrl = useMemo(() => {
        if (pdfData) {
            return URL.createObjectURL(pdfData);
        }
        return null;
    }, [pdfData]);

    useEffect(() => {
        return () => {
            if (pdfDataUrl) {
                URL.revokeObjectURL(pdfDataUrl);
            }
        };
    }, [pdfDataUrl]);

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        console.log('Document loaded successfully with', numPages, 'pages');
        setNumPages(numPages);
    }, []);

    const onDocumentLoadError = useCallback((error) => {
        console.error('Error loading document:', error);
        setError(error);
    }, []);

    const options = useMemo(() => ({
        cMapUrl: 'https://unpkg.com/pdfjs-dist@2.9.359/cmaps/',
        cMapPacked: true,
    }), []);

    const getWordAtPosition = useCallback((x, y) => {
        let range;
        if (document.caretPositionFromPoint) {
            const position = document.caretPositionFromPoint(x, y);
            if (position) {
                range = document.createRange();
                range.setStart(position.offsetNode, position.offset);
                range.setEnd(position.offsetNode, position.offset);
            }
        } else if (document.caretRangeFromPoint) {
            range = document.caretRangeFromPoint(x, y);
        }

        if (range) {
            range.expand('word');
            return range.toString().trim();
        }
        return null;
    }, []);

    const calculateOptimalPosition = useCallback((clickX, clickY, wordWidth, wordHeight) => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const popupWidth = 300;
        const popupHeight = 200;
        const isLandscape = viewportWidth > viewportHeight;

        let x = clickX;
        let y = clickY;

        if (x + popupWidth > viewportWidth) {
            x = isLandscape ? viewportWidth - popupWidth : Math.max(0, x - popupWidth);
        }

        if (y + popupHeight > viewportHeight) {
            y = Math.max(0, y - popupHeight - wordHeight);
        }

        x = Math.max(0, x);
        y = Math.max(0, y);

        return { x, y };
    }, []);

    const handlePageClick = useCallback((event) => {
        if (wordExplanationRef.current && wordExplanationRef.current.contains(event.target)) {
            return;
        }

        if (event.target.tagName === 'SPAN' && event.target.getAttribute('role') === 'presentation') {
            const word = getWordAtPosition(event.clientX, event.clientY);
            if (word && word.match(/\b\w+('\w+)?\b/)) {
                const baseWord = word.match(/\b\w+('\w+)?\b/)[0].toLowerCase();
                console.log("Clicked word:", baseWord);

                if (!wordCache[baseWord]) {
                    get(`/api/word/${baseWord}`)
                        .then(data => {
                            setWordCache(prev => ({ ...prev, [baseWord]: data }));
                        })
                        .catch(error => {
                            console.error('Error fetching word information:', error);
                        });
                }

                const selection = window.getSelection();
                const range = selection.getRangeAt(0);
                const rect = range.getBoundingClientRect();

                const newPosition = calculateOptimalPosition(
                    event.clientX,
                    event.clientY,
                    rect.width,
                    rect.height
                );

                setWordExplanation({
                    word: baseWord,
                    position: newPosition,
                    isVisible: true
                });
                setShowExplanation(true);
            } else {
                setShowExplanation(false);
                setWordExplanation(prev => ({ ...prev, isVisible: false }));
            }
        } else {
            setShowExplanation(false);
            setWordExplanation(prev => ({ ...prev, isVisible: false }));
        }
    }, []);

    const onPageRenderSuccess = useCallback((page) => {
        const { height } = page.view;
        setPageHeight(height);
    }, []);

    const renderPage = useCallback(({ index, style }) => (
        <div style={style}>
            <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={pageWidth}
                onRenderSuccess={onPageRenderSuccess}
                onClick={handlePageClick}
            />
        </div>
    ), [pageWidth, handlePageClick, onPageRenderSuccess]);

    const pdfList = useMemo(() => {
        if (!numPages) return null;
        return (
            <List
                ref={listRef}
                height={window.innerHeight - 40}
                itemCount={numPages}
                itemSize={pageHeight || pageWidth * 1.4}
                width={pageWidth}
                className="pdf-list"
            >
                {renderPage}
            </List>
        );
    }, [numPages, pageWidth, pageHeight, renderPage]);

    return (
        <div className="pdf-container" style={{
            width: '100%',
            maxWidth: '1000px',
            height: 'calc(100vh - 40px)',
            padding: '0 0',
            margin: '0 auto',
            overflow: 'hidden'
        }} ref={containerRef}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '100%',
                margin: '0 auto'
            }}
                onClick={(e) => {
                    if (e.target.tagName !== 'SPAN' && e.target.getAttribute('role') !== 'presentation') {
                        setShowExplanation(false);
                    }
                }}>
                {error ? (
                    <div>An error occurred: {error.message}</div>
                ) : (
                    <>
                        {pdfDataUrl && (
                            <Document
                                file={pdfDataUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={onDocumentLoadError}
                                options={options}
                            >
                                {numPages ? (
                                    pdfList
                                ) : (
                                    <div>Loading PDF: {loadingProgress}%</div>
                                )}
                            </Document>
                        )}
                        {!pdfDataUrl && <div>Loading PDF: {loadingProgress}%</div>}
                    </>
                )}
                {showExplanation && (
                    <div ref={wordExplanationRef}>
                        <WordExplanation
                            word={wordExplanation.word}
                            position={wordExplanation.position}
                            onClose={() => {
                                setShowExplanation(false);
                                setWordExplanation(prev => ({ ...prev, isVisible: false }));
                            }}
                            show={showExplanation}
                            wordCache={wordCache}
                            caller="PDFViewer"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(PDFViewer);