import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    Book,
    FileText,
    BookOpen,
    ChevronDown,
    ChevronUp,
    Menu,
    X,
    CreditCard,
    RefreshCw,
    BarChart,
    Plus,
    User,
} from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import SearchBar from './SearchBar';

const Layout = ({ children, noPadding }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isReadingCenterOpen, setIsReadingCenterOpen] = useState(false);
    const [isFlashcardsOpen, setIsFlashcardsOpen] = useState(false);
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);

    const navItems = [
        {
            name: '阅读中心',
            icon: Book,
            subItems: [
                { name: '文章', icon: FileText, path: '/articles' },
                { name: 'PDF文档', icon: FileText, path: '/pdfs' },
                { name: 'EPUB书籍', icon: BookOpen, path: '/epubs' },
            ],
        },
        {
            name: '闪卡管理',
            icon: CreditCard,
            subItems: [
                { name: '复习', icon: RefreshCw, path: '/flashcards/review' },
                { name: '统计', icon: BarChart, path: '/flashcards/stats' },
                { name: '制卡', icon: Plus, path: '/flashcards/create' },
            ],
        },
    ];

    useLayoutEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    }, [showHeader, isMobile]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                isMenuOpen &&
                isMobile &&
                !event.target.closest('nav') &&
                !event.target.closest('button.menu-button')
            ) {
                setIsMenuOpen(false);
            }

            // 关闭桌面端用户菜单
            if (!isMobile && isUserMenuOpen && !event.target.closest('.user-menu')) {
                setIsUserMenuOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [isMenuOpen, isMobile, isUserMenuOpen]);

    const toggleMenu = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleHeader = () => {
        setShowHeader(!showHeader);
    };

    const toggleUserMenu = (event) => {
        event.stopPropagation();
        setIsUserMenuOpen(!isUserMenuOpen);
    };

    const { logout } = useAuth();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
            // 处理错误，例如显示提示信息
        }
    };

    return (
        <div className="flex flex-col min-h-screen w-full">
            {/* Header 区域 */}
            <header
                ref={headerRef}
                className={`
                    bg-white border-b border-gray-300 p-2 flex items-center z-50 fixed top-0 left-0 right-0
                    transition-transform duration-300 ease-in-out
                    ${showHeader ? 'transform translate-y-0' : 'transform -translate-y-full'}
                `}
            >
                {/* 左侧：收起/展开按钮 */}
                {isMobile && (
                    <button onClick={toggleHeader} className="text-gray-600 menu-button mr-2">
                        {showHeader ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                    </button>
                )}
                {/* 中间：搜索栏 */}
                <div className="flex-grow flex justify-center">
                    <SearchBar />
                </div>
                {/* 右侧：导航菜单和用户信息 */}
                <div className="flex items-center">
                    {/* 导航菜单（仅在大屏幕显示） */}
                    <nav className="hidden lg:flex space-x-6 items-center">
                        {navItems.map((item) => (
                            <div key={item.name} className="relative group">
                                {item.subItems ? (
                                    <>
                                        <button
                                            // 移除桌面端的 Chevron 图标
                                            onClick={(e) => e.preventDefault()}
                                            className="flex items-center text-gray-700 hover:text-blue-600 focus:outline-none"
                                        >
                                            <item.icon className="mr-1" size={20} />
                                            <span>{item.name}</span>
                                        </button>
                                        {/* 子菜单 */}
                                        <div className="absolute left-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                                            <ul>
                                                {item.subItems.map((subItem) => (
                                                    <li key={subItem.name}>
                                                        <Link
                                                            to={subItem.path}
                                                            className={`block px-4 py-2 text-gray-700 hover:bg-gray-100 ${
                                                                location.pathname === subItem.path
                                                                    ? 'bg-gray-100 font-semibold'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <subItem.icon className="mr-2 inline" size={16} />
                                                            {subItem.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                ) : (
                                    <Link
                                        to={item.path}
                                        className={`flex items-center text-gray-700 hover:text-blue-600 ${
                                            location.pathname === item.path ? 'font-semibold' : ''
                                        }`}
                                    >
                                        <item.icon className="mr-1" size={20} />
                                        <span>{item.name}</span>
                                    </Link>
                                )}
                            </div>
                        ))}
                    </nav>

                    {/* 用户信息菜单（仅在桌面端显示） */}
                    {!isMobile && (
                        <div className="relative ml-4 user-menu">
                            <button
                                onClick={toggleUserMenu}
                                className="flex items-center focus:outline-none"
                            >
                                <User className="w-8 h-8 text-gray-700" />
                                {/* 移除桌面端的 Chevron 图标 */}
                            </button>
                            {/* 下拉菜单 */}
                            {isUserMenuOpen && (
                                <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded shadow-lg transition-opacity duration-200">
                                    <ul>
                                        <li>
                                            <button
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                onClick={() => console.log('Settings')}
                                            >
                                                设置
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                onClick={() => console.log('Subscription')}
                                            >
                                                订阅
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                onClick={handleLogout}
                                            >
                                                退出登录
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}

                    {/* 移动端菜单按钮 */}
                    {isMobile && (
                        <div className="ml-2">
                            <button onClick={toggleMenu} className="text-gray-600 menu-button">
                                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                            </button>
                        </div>
                    )}
                </div>
            </header>

            {/* 如果 header 被收起，显示展开按钮（仅在小屏幕） */}
            {!showHeader && isMobile && (
                <button
                    onClick={toggleHeader}
                    className="fixed top-0 right-0 z-50 p-2 "
                >
                    <ChevronDown size={24} />
                </button>
            )}

            {/* 移动端菜单 */}
            {isMenuOpen && isMobile && (
                <nav
                    className="lg:hidden bg-white shadow-md fixed top-0 left-0 right-0 z-40 overflow-auto"
                    style={{ marginTop: showHeader ? `${headerHeight}px` : '0' }}
                >
                    <ul className="space-y-1 p-4">
                        {navItems.map((item) => (
                            <li key={item.name}>
                                {item.subItems ? (
                                    <div>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation(); // 防止事件冒泡
                                                if (item.name === '阅读中心') {
                                                    setIsReadingCenterOpen(!isReadingCenterOpen);
                                                } else if (item.name === '闪卡管理') {
                                                    setIsFlashcardsOpen(!isFlashcardsOpen);
                                                }
                                            }}
                                            className="flex items-center w-full p-2 rounded-lg text-gray-700 hover:bg-gray-100 focus:outline-none"
                                        >
                                            <item.icon className="mr-2" size={20} />
                                            <span>{item.name}</span>
                                            {((item.name === '阅读中心' && isReadingCenterOpen) ||
                                            (item.name === '闪卡管理' && isFlashcardsOpen)) ? (
                                                <ChevronUp className="ml-auto" size={16} />
                                            ) : (
                                                <ChevronDown className="ml-auto" size={16} />
                                            )}
                                        </button>
                                        {((item.name === '阅读中心' && isReadingCenterOpen) ||
                                        (item.name === '闪卡管理' && isFlashcardsOpen)) && (
                                            <ul className="ml-4 mt-2 space-y-1">
                                                {item.subItems.map((subItem) => (
                                                    <li key={subItem.name}>
                                                        <Link
                                                            to={subItem.path}
                                                            className={`flex items-center p-2 rounded-lg ${
                                                                location.pathname === subItem.path
                                                                    ? 'bg-gray-200 text-gray-800'
                                                                    : 'text-gray-700 hover:bg-gray-100'
                                                            }`}
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // 防止菜单关闭
                                                                setIsMenuOpen(false); // 仅在点击链接时关闭菜单
                                                            }}
                                                        >
                                                            <subItem.icon className="mr-2" size={16} />
                                                            <span>{subItem.name}</span>
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ) : (
                                    <Link
                                        to={item.path}
                                        className={`flex items-center p-2 rounded-lg ${
                                            location.pathname === item.path
                                                ? 'bg-gray-200 text-gray-800'
                                                : 'text-gray-700 hover:bg-gray-100'
                                        }`}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        <item.icon className="mr-2" size={20} />
                                        <span>{item.name}</span>
                                    </Link>
                                )}
                            </li>
                        ))}
                        {/* 用户信息菜单（移动端） */}
                        <li>
                            <div>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation(); // 防止事件冒泡
                                        setIsUserMenuOpen(!isUserMenuOpen);
                                    }}
                                    className="flex items-center w-full p-2 rounded-lg text-gray-700 hover:bg-gray-100 focus:outline-none"
                                >
                                    <User className="mr-2" size={20} />
                                    <span>用户</span>
                                    {isUserMenuOpen ? (
                                        <ChevronUp className="ml-auto" size={16} />
                                    ) : (
                                        <ChevronDown className="ml-auto" size={16} />
                                    )}
                                </button>
                                {isUserMenuOpen && (
                                    <ul className="ml-4 mt-2 space-y-1">
                                        <li>
                                            <button
                                                className="flex items-center p-2 rounded-lg text-gray-700 hover:bg-gray-100 focus:outline-none w-full text-left"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    console.log('Settings');
                                                    setIsMenuOpen(false);
                                                }}
                                            >
                                                <span>设置</span>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className="flex items-center p-2 rounded-lg text-gray-700 hover:bg-gray-100 focus:outline-none w-full text-left"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    console.log('Subscription');
                                                    setIsMenuOpen(false);
                                                }}
                                            >
                                                <span>订阅</span>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className="flex items-center p-2 rounded-lg text-gray-700 hover:bg-gray-100 focus:outline-none w-full text-left"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleLogout();
                                                    setIsMenuOpen(false);
                                                }}
                                            >
                                                <span>退出登录</span>
                                            </button>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </li>
                    </ul>
                </nav>
            )}

            <div
                className="flex flex-1 overflow-hidden relative"
                style={{ paddingTop: showHeader ? `${headerHeight}px` : '0px' }}
            >
                <main className={`flex-1 overflow-auto ${noPadding ? 'p-0' : 'p-4'}`}>
                    {children}
                </main>
            </div>
        </div>
    );

};

export default Layout;