import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useApi } from '@/utils/api';

const PasswordResetRequestForm = () => {
  const api = useApi();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      const response = await api.post('/api/password-reset-request', { email });
      if (response.ok) {
        setMessage('密码重置邮件已发送到您的邮箱');
      } else {
        setError('发送失败，请重试');
      }
    } catch (err) {
      setError('发生错误，请稍后重试');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-[20rem] p-6 bg-white shadow-md rounded">
        <h2 className="text-2xl font-semibold text-center text-gray-700">重置密码</h2>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mt-4">
            <Input
              type="email"
              placeholder="邮箱"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full"
            />
          </div>
          <Button type="submit" className="w-full mt-6 bg-[#5972aa] text-white hover:bg-[#4b68a9]">
            发送重置邮件
          </Button>
        </form>
        {error && <p className="text-red-500 text-sm mt-4 text-center">{error}</p>}
        {message && <p className="text-green-500 text-sm mt-4 text-center">{message}</p>}
        <div className="text-center mt-6">
          <Link to="/login" className="text-sm text-[#5972aa] hover:underline">返回登录</Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetRequestForm;