import React, { useState, useEffect, useRef, useCallback } from 'react';
import ePub from 'epubjs';
import { useLocation } from 'react-router-dom';
import WordExplanation from './WordExplanation';
import { calculatePosition } from '../utils/positionUtils';
import { useApi } from '../utils/api';

const EPUBViewer = () => {
    const api = useApi();
    const location = useLocation();
    const file = location.state?.file;
    console.log(`EPUBViewer, file: ${file}`);
    const viewerRef = useRef(null);
    const [error, setError] = useState(null);
    const [size, setSize] = useState(100);
    const [rendition, setRendition] = useState(null);
    const [wordExplanation, setWordExplanation] = useState({
        word: '',
        position: { x: 0, y: 0 },
        isVisible: false
    });
    const [wordCache, setWordCache] = useState({});
    const [showExplanation, setShowExplanation] = useState(false);

    useEffect(() => {
        const book = ePub(file);
        const rendition = book.renderTo(viewerRef.current, {
            width: '100%',
            height: '100%',
            flow: 'scrolled',
            manager: 'continuous'
        });
        setRendition(rendition);

        book.ready.then(() => {
            rendition.display();
            rendition.themes.default({ body: { padding: '10px' } });
            rendition.themes.fontSize(`${size}%`);
        }).catch(error => {
            console.error('Failed to load EPUB:', error);
            setError(`Failed to load the book. Error: ${error.message || 'Unknown error'}`);
        });

        return () => {
            book.destroy();
        };
    }, [file, size]);

    const changeFontSize = (delta) => {
        setSize(prevSize => Math.max(80, Math.min(150, prevSize + delta)));
    };

    const handleClick = useCallback(async (cfiRange, contents) => {
        if (!rendition) return;

        const range = contents.range(cfiRange);
        if (!range) return;

        const text = range.toString().trim();
        if (!text || !text.match(/\b\w+('\w+)?\b/)) return;

        const word = text.match(/\b\w+('\w+)?\b/)[0].toLowerCase();
        console.log("Selected word:", word);

        if (!wordCache[word]) {
            try {
                const response = await api.get(`/api/word/${word}`);
                const data = await response.json();
                setWordCache(prev => ({ ...prev, [word]: data }));
            } catch (error) {
                console.error('Error fetching word information:', error);
            }
        }

        const rect = range.getBoundingClientRect();
        const newPosition = calculatePosition({
            right: rect.right,
            top: rect.top,
            left: rect.left
        });

        setWordExplanation({
            word: word,
            position: newPosition,
            isVisible: true
        });
        setShowExplanation(true);
    }, [rendition, wordCache]);

    useEffect(() => {
        if (rendition) {
            rendition.on('selected', handleClick);
            return () => {
                rendition.off('selected', handleClick);
            };
        }
    }, [rendition, handleClick]);

    return (
        <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
            {error && <div>Error: {error}</div>}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: 40,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0 10px',
                zIndex: 10
            }}>
                <button onClick={() => changeFontSize(-10)} disabled={!!error}>A-</button>
                <span style={{ margin: '0 10px' }}>{size}%</span>
                <button onClick={() => changeFontSize(10)} disabled={!!error}>A+</button>
            </div>
            <div ref={viewerRef} style={{ width: '100%', height: 'calc(100% - 40px)', overflowY: 'scroll' }}></div>
            {showExplanation && (
                <WordExplanation
                    word={wordExplanation.word}
                    position={wordExplanation.position}
                    onClose={() => {
                        setShowExplanation(false);
                        setWordExplanation(prev => ({ ...prev, isVisible: false }));
                    }}
                    wordCache={wordCache}
                />
            )}
        </div>
    );
};

export default EPUBViewer;