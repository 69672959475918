import React from 'react';
import { IconClipboard, IconRowInsertBottom, IconRowInsertTop } from '@tabler/icons-react';

const ImageMenu = ({ position, onSelect }) => {
    return (
        <div
            className="fixed bg-white border rounded shadow-lg"
            style={{ left: `${position.x-126}px`, top: `${position.y}px` }}
        >
            <button
                className="flex items-center w-full text-left px-3 py-2 hover:bg-gray-100"
                onClick={() => onSelect('local', 'above')}
            >
                <IconRowInsertTop className="mr-2" />
                Insert above
            </button>
            <button
                className="flex items-center w-full text-left px-3 py-2 hover:bg-gray-100"
                onClick={() => onSelect('local', 'below')}
            >
                <IconRowInsertBottom className="mr-2" />
                Insert below
            </button>
            <button
                className="flex items-center w-full text-left px-3 py-2 hover:bg-gray-100"
                onClick={() => onSelect('paste', 'above')}
            >
                <IconClipboard className="mr-2" />
                Paste above
            </button>
            <button
                className="flex items-center w-full text-left px-3 py-2 hover:bg-gray-100"
                onClick={() => onSelect('paste', 'below')}
            >
                <IconClipboard className="mr-2" />
                Paste below
            </button>
        </div>
    );
};

export default ImageMenu;
