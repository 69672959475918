import { useState } from 'react';
import { calculatePosition } from '@/utils/positionUtils';
import { useApi } from '../utils/api';

const useWordHandler = () => {
    const api = useApi();
    const [selectedWord, setSelectedWord] = useState(null);
    const [wordCache, setWordCache] = useState({});
    const [showExplanation, setShowExplanation] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleWordClick = async (word, event) => {
        if (!word || !word.match(/\b\w+('\w+)?\b/)) {
            return;
        }

        const baseWord = word.match(/\b\w+('\w+)?\b/)[0].toLowerCase();
        setSelectedWord(baseWord);

        if (!wordCache[baseWord]) {
            try {
                const response = await api.get(`/api/word/${baseWord}`);
                const data = await response.json();
                setWordCache(prev => ({ ...prev, [baseWord]: data }));
            } catch (error) {
                console.error('Error fetching word information:', error);
            }
        }

        const rect = event.target.getBoundingClientRect();
        const newPosition = calculatePosition(rect);
        setPosition(newPosition);
        setShowExplanation(true);
    };

    return {
        selectedWord,
        wordCache,
        showExplanation,
        position,
        handleWordClick,
        setShowExplanation,
    };
};

export default useWordHandler;
