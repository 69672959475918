import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconEye, IconEyeOff, IconEdit, IconCheck, IconX } from '@tabler/icons-react';
import WordExplanation from '@/components/WordExplanation';
import { calculatePosition } from '@/utils/positionUtils';
import { useApi } from '../utils/api';

const ArticleView = () => {
    const api = useApi();
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [showTranslation, setShowTranslation] = useState({});
    const [selectedWord, setSelectedWord] = useState(null);
    const [wordCache, setWordCache] = useState({});
    const [showExplanation, setShowExplanation] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [showEnglish, setShowEnglish] = useState(true);
    const [showChinese, setShowChinese] = useState(true);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedText, setEditedText] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchArticle();
    }, [id]);

    const fetchArticle = async () => {
        try {
            setIsLoading(true)
            const data = await api.get(`/api/articles/${id}`);
            console.log("Fetched article data:", data);
            setArticle(data);

            const initialShowTranslation = data.content.reduce((acc, item, index) => {
                if (item.type === 'text' && item.text) {
                    acc[index] = true;
                }
                return acc;
            }, {});
            setShowTranslation(initialShowTranslation);

        } catch (error) {
            console.error('Error fetching article:', error);
            // 错误处理逻辑
        } finally {
            setIsLoading(false)
        }
    };

    const toggleTranslation = (index) => {
        setShowTranslation(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleWordClick = async (word, event) => {
        // ... (existing word click handling code)
        if (!word || !word.match(/\b\w+('\w+)?\b/)) {
            return;
        }

        const baseWord = word.match(/\b\w+('\w+)?\b/)[0].toLowerCase();
        setSelectedWord(baseWord);

        if (!wordCache[baseWord]) {
            try {
                const data = await api.get(`/api/word/${baseWord}`);
                setWordCache(prev => ({ ...prev, [baseWord]: data }));
            } catch (error) {
                console.error('Error fetching word information:', error);
            }
        } else {
            // 单词存在于缓存中，异步调用更新接口，但不等待结果
            api.post(`/api/update-word/${baseWord}`).catch(error => {
                console.error('Error updating word information:', error);
            });
        }

        const rect = event.target.getBoundingClientRect();
        const newPosition = calculatePosition(rect);
        setPosition(newPosition);
        setShowExplanation(true);
    };

    const toggleLanguage = (language) => {
        if (language === 'english') {
            setShowEnglish(prevShowEnglish => {
                const newShowEnglish = !prevShowEnglish;
                setShowTranslation(prevState => {
                    const newState = { ...prevState };
                    Object.keys(newState).forEach(index => {
                        if (isEnglish(article.content[index].text)) {
                            newState[index] = newShowEnglish;
                        }
                    });
                    return newState;
                });
                return newShowEnglish;
            });
        } else if (language === 'chinese') {
            setShowChinese(prevShowChinese => {
                const newShowChinese = !prevShowChinese;
                setShowTranslation(prevState => {
                    const newState = { ...prevState };
                    Object.keys(newState).forEach(index => {
                        if (!isEnglish(article.content[index].text)) {
                            newState[index] = newShowChinese;
                        }
                    });
                    return newState;
                });
                return newShowChinese;
            });
        }
    };

    const shouldShowSentence = (index) => {
        return showTranslation[index];
    };

    const isEnglish = (text) => /^[A-Za-z]/.test(text);

    const handleEditClick = (index) => {
        setEditingIndex(index);
        setEditedText(article.content[index].text);
    };

    const handleEditCancel = () => {
        setEditingIndex(null);
        setEditedText('');
    };

    const handleEditSave = async (index) => {
        try {
            const updatedContent = [...article.content];
            updatedContent[index] = { ...updatedContent[index], text: editedText };

            const response = await api.put(`/api/articles/${id}`, { ...article, content: updatedContent });

            if (response.ok) {
                setArticle({ ...article, content: updatedContent });
                setEditingIndex(null);
                setEditedText('');
            } else {
                console.error('Failed to update article');
            }
        } catch (error) {
            console.error('Error updating article:', error);
        }
    };
    if (!article) {
        return <div>Loading...</div>;
    }
    return (
        <div className="container mx-auto pt-6">
            <h1 className="text-2xl font-bold mb-4 text-center">{article.title}</h1>
            <div className="mb-4 flex justify-center space-x-4">
                {/* ... (existing language toggle buttons) */}
                <button
                    onClick={() => toggleLanguage('english')}
                    className="toggle-button-en px-4 py-2 bg-[#657b9f] text-white rounded hover:bg-[#5a6a84] focus:outline-none"
                >
                    {showEnglish ? '隐藏英文' : '显示英文'}
                </button>
                <button
                    onClick={() => toggleLanguage('chinese')}
                    className="toggle-button-ch px-4 py-2 bg-[#d17b78] text-white rounded hover:bg-[#b7716e] focus:outline-none"
                >
                    {showChinese ? '隐藏中文' : '显示中文'}
                </button>
            </div>
            <div className="prose">
                {article.content && article.content.map((sentence, index) => (
                    <div key={index} className="group relative mb-2 flex items-center">
                        {sentence.type === 'image' ? (
                            <div className="image-wrapper">
                                {/* ... (existing image rendering code) */}
                                <div className="image-container">
                                    <img
                                        src={`/api/files/serve/${sentence.src}`}
                                        alt="Article content"
                                        width={sentence.width}
                                        height={sentence.height}
                                        style={{ maxWidth: '100%' }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <>
                                {sentence.text && sentence.text.trim() && (
                                    <button
                                        onClick={() => toggleTranslation(index)}
                                        className="mr-2 p-1 text-gray-500 hover:text-gray-700 focus:outline-none"
                                    >
                                        {shouldShowSentence(index) ? <IconEye size={16} opacity={0.2} /> : <IconEyeOff size={16} />}
                                    </button>
                                )}
                                {editingIndex === index ? (
                                    <div className="flex-grow flex items-center">
                                        <textarea
                                            value={editedText}
                                            onChange={(e) => setEditedText(e.target.value)}
                                            className="w-full p-2 border rounded"
                                        />
                                        <button
                                            onClick={() => handleEditSave(index)}
                                            className="ml-2 p-1 text-green-500 hover:text-green-700 focus:outline-none"
                                        >
                                            <IconCheck size={20} />
                                        </button>
                                        <button
                                            onClick={handleEditCancel}
                                            className="ml-2 p-1 text-red-500 hover:text-red-700 focus:outline-none"
                                        >
                                            <IconX size={20} />
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <pre className="font-sans whitespace-pre-wrap break-words flex-grow">
                                            {shouldShowSentence(index) && sentence.text && sentence.text.trim() ? (
                                                sentence.text.split(/(\S+)/).map((part, partIndex) => (
                                                    <React.Fragment key={partIndex}>
                                                        {part.trim() ? (
                                                            <span
                                                                className="cursor-pointer hover:bg-blue-100 rounded"
                                                                onClick={(e) => handleWordClick(part, e)}
                                                            >
                                                                {part}
                                                            </span>
                                                        ) : part}
                                                    </React.Fragment>
                                                ))
                                            ) : <span>&nbsp;</span>}
                                        </pre>
                                        <button
                                            onClick={() => handleEditClick(index)}
                                            className="ml-2 p-1 text-[#eeeeee] hover:text-[#c0bfbf] focus:outline-none"
                                        >
                                            <IconEdit size={20} />
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
            {showExplanation && selectedWord && (
                <WordExplanation
                    word={selectedWord}
                    onClose={() => setShowExplanation(false)}
                    position={position}
                    wordCache={wordCache}
                />
            )}
        </div>
    );
};

export default ArticleView;