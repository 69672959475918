import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useApi } from '@/utils/api';

const PasswordResetForm = () => {
  const api = useApi();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (newPassword !== confirmPassword) {
      setError('两次输入的密码不匹配');
      return;
    }

    try {
      const response = await api.post('/api/password-reset', { token, new_password: newPassword });
      if (response.ok) {
        setMessage('密码已成功重置');
        setTimeout(() => navigate('/login'), 3000);
      } else {
        setError('重置密码失败，请重试');
      }
    } catch (err) {
      setError('发生错误，请稍后重试');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-[20rem] p-6 bg-white shadow-md rounded">
        <h2 className="text-2xl font-semibold text-center text-gray-700">设置新密码</h2>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mt-4">
            <Input
              type="password"
              placeholder="新密码"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="w-full"
            />
          </div>
          <div className="mt-4">
            <Input
              type="password"
              placeholder="确认新密码"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full"
            />
          </div>
          <Button type="submit" className="w-full mt-6 bg-[#5972aa] text-white hover:bg-[#4b68a9]">
            重置密码
          </Button>
        </form>
        {error && <p className="text-red-500 text-sm mt-4 text-center">{error}</p>}
        {message && <p className="text-green-500 text-sm mt-4 text-center">{message}</p>}
      </div>
    </div>
  );
};

export default PasswordResetForm;