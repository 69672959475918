import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useApi } from '@/utils/api';

const RegisterForm = () => {
    const api = useApi();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    // 添加允许的邮箱域名列表
    const allowedDomains = [
        '@gmail.com',
        '@outlook.com',
        '@yahoo.com',
        '@icloud.com',
        '@qq.com',
        '@163.com',
        '@hotmail.com',
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        // 验证邮箱域名
        const emailDomain = email.substring(email.lastIndexOf('@'));
        if (!allowedDomains.includes(emailDomain.toLowerCase())) {
            setError('不支持的邮箱域名，请使用常用邮箱注册');
            setIsLoading(false);
            return;
        }

        try {
            await api.post('/api/register', { username, email, password });
            navigate('/verify-email', { state: { email } });
        } catch (error) {
            if (error.message === 'Request timed out') {
                setError('注册请求超时，但可能已成功。请检查您的邮箱，或稍后重试。');
            } else {
                setError('注册过程中出现错误，请重试');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
            <div className="w-full max-w-[20rem] p-6 bg-white shadow-md rounded">
                <h2 className="text-2xl font-semibold text-center text-gray-700">创建新账户</h2>
                <form onSubmit={handleSubmit} className="mt-4">
                    <div className="mt-4">
                        <Input
                            type="text"
                            placeholder="用户名"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="w-full"
                        />
                    </div>
                    <div className="mt-4">
                        <Input
                            type="email"
                            placeholder="邮箱"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full"
                        />
                    </div>
                    <div className="mt-4">
                        <Input
                            type="password"
                            placeholder="密码"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="w-full"
                        />
                    </div>
                    <Button type="submit" className="w-full mt-6 bg-[#5972aa] text-white hover:bg-[#4b68a9]" disabled={isLoading}>
                        {isLoading ? (
                            <div className="flex justify-center items-center">
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                                <span className="ml-2">处理中...</span>
                            </div>
                        ) : (
                            '注册'
                        )}
                    </Button>
                </form>
                {error && <p className="text-red-500 text-sm mt-4 text-center">{error}</p>}
                <div className="text-center mt-6">
                    <Link to="/login" className="text-sm text-[#5972aa] hover:underline">已有账户？登录</Link>
                </div>
            </div>
        </div>
    );
};

export default RegisterForm;