import React, { useState, useEffect } from 'react';
import { useApi } from '../../utils/api';

const FlashcardList = () => {
    const [dueCards, setDueCards] = useState([]);
    const { get } = useApi();

    useEffect(() => {
        const fetchDueCards = async () => {
            try {
                const response = await get('/api/flashcards/due');
                setDueCards(response.due_cards);
            } catch (error) {
                console.error('Error fetching due cards:', error);
            }
        };

        fetchDueCards();
    }, [get]);

    return (
        <div>
            <h2>待复习闪卡</h2>
            <ul>
                {dueCards.map(card => (
                    <li key={card.word_id}>{card.word}</li>
                ))}
            </ul>
        </div>
    );
};

export default FlashcardList;