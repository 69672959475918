import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useApi } from '../../utils/api';
import { motion, AnimatePresence } from 'framer-motion';
import { useDialog } from '../../utils/DialogComponent';
import { parseISO } from 'date-fns';

const FlashcardReview = () => {
    const [currentCard, setCurrentCard] = useState(null);
    const [showBack, setShowBack] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const { get, post } = useApi();
    const containerRef = useRef(null);
    const { showErrorDialog, showInfoDialog } = useDialog();
    const [nextReviewTimes, setNextReviewTimes] = useState(null);
    const [isReviewComplete, setIsReviewComplete] = useState(false);

    // 映射评分到数字
    const ratingMap = {
        'Again': 1,
        'Hard': 2,
        'Good': 3,
        'Easy': 4
    };

    // 格式化时间为本地时间
    const formatTime = (dateString) => {
        const date = parseISO(dateString); // 解析为 UTC 时间
        const now = new Date();
        const diffTime = date - now;
        if (diffTime <= 0) return '立即';
        const diffMinutes = Math.ceil(diffTime / (1000 * 60));
        if (diffMinutes < 60) return `${diffMinutes}分钟`;
        const diffHours = Math.ceil(diffMinutes / 60);
        if (diffHours < 24) return `${diffHours}小时`;
        const diffDays = Math.ceil(diffHours / 24);
        return `${diffDays}天`;
    };

    // 播放音频
    const playAudio = useCallback((url) => {
        if (!url) return;
        const audio = new Audio(url);
        audio.play().catch(e => {
            console.error('Error playing audio:', e);
            showErrorDialog('播放音频失败', '无法播放音频，请检查您的网络连接。');
        });
    }, [showErrorDialog]);

    const dialogShownRef = useRef(false);

    const fetchNextCard = useCallback(async () => {
        if (isFetching || dialogShownRef.current) return;
        setIsFetching(true);
        try {
            const response = await get('/api/flashcards/due');
            if (response.due_cards && response.due_cards.length > 0) {
                setCurrentCard(response.due_cards[0]);
                setNextReviewTimes(response.due_cards[0].next_review_times); // 设置 next_review_times
                setShowBack(false);
                setIsReviewComplete(false);
            } else {
                setCurrentCard(null);
                setIsReviewComplete(true);
                if (!dialogShownRef.current) {
                    showInfoDialog('复习完成', '恭喜您完成所有待复习的闪卡！');
                    dialogShownRef.current = true;
                }
            }
        } catch (error) {
            console.error('Error fetching next card:', error);
            showErrorDialog('获取卡片失败', '无法获取下一张卡片，请稍后再试。');
        } finally {
            setIsFetching(false);
            setIsLoading(false);
        }
    }, [get, showErrorDialog, showInfoDialog, isFetching]);

    // 重置函数
    // const resetReview = useCallback(() => {
    //     dialogShownRef.current = false;
    //     fetchNextCard();
    // }, [fetchNextCard]);

    // 提交复习结果
    const handleAnswer = useCallback(async (rating, event) => {
        if (!currentCard) return;
        // 移除按钮的焦点
        event.currentTarget.blur();
        try {
            const response = await post('/api/flashcards/review', {
                word: currentCard.word,
                rating: ratingMap[rating]
            });
            setNextReviewTimes(response.next_review_times);
            fetchNextCard();
        } catch (error) {
            console.error('Error submitting review:', error);
            showErrorDialog('提交复习失败', '无法提交复习结果，请稍后再试。');
        }
    }, [currentCard, post, ratingMap, fetchNextCard, showErrorDialog]);

    // 处理快捷键
    const handleKeyPress = useCallback((event) => {
        if (!currentCard) return;
        if (event.key === 'c') {
            event.preventDefault();
            setShowBack(prev => !prev);
            if (!showBack) {
                const audioUrl = currentCard.soundmark?.uk?.[1] || currentCard.soundmark?.usa?.[1];
                playAudio(audioUrl);
            }
        } else if (showBack) {
            switch (event.key) {
                case 'j':
                    handleAnswer('Again', event);
                    break;
                case 'k':
                    handleAnswer('Hard', event);
                    break;
                case 'l':
                    handleAnswer('Good', event);
                    break;
                case ';':
                    handleAnswer('Easy', event);
                    break;
                default:
                    break;
            }
        }
    }, [currentCard, showBack, handleAnswer, playAudio]);

    // 绑定键盘事件
    useEffect(() => {
        const refCurrent = containerRef.current;
        refCurrent?.focus();
        refCurrent?.addEventListener('keydown', handleKeyPress);
        return () => {
            refCurrent?.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    // 初始加载卡片
    useEffect(() => {
        fetchNextCard();
    }, []);

    // 防止页面滚动，允许闪卡内容滚动
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    // 翻转卡片
    const handleFlip = useCallback((event) => {
        const selection = window.getSelection();
        if (selection && selection.toString().length > 0) {
            // 有文本被选中，避免翻转
            return;
        }
        setShowBack(prev => !prev);
        if (!showBack && currentCard) {
            const audioUrl = currentCard.soundmark?.uk?.[1] || currentCard.soundmark?.usa?.[1];
            playAudio(audioUrl);
        }
    }, [showBack, currentCard, playAudio]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-50 to-purple-50">
                <div className="flex items-center space-x-2">
                    <svg className="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span className="ml-2 text-gray-600">加载中...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-between h-screen bg-gradient-to-r from-blue-50 to-purple-50 p-4 relative focus:outline-none" ref={containerRef} tabIndex={0}>
            <div className="flex-grow flex items-center justify-center w-full">
                <AnimatePresence mode="wait">
                    {currentCard ? (
                        <motion.div
                            key={currentCard.word}
                            className="w-full max-w-lg bg-white rounded-xl shadow-lg p-6 cursor-pointer overflow-auto max-h-full"
                            onClick={handleFlip}
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                            transition={{ duration: 0.3 }}
                        >
                            {!showBack ? (
                                <motion.div
                                    key="front"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    className="flex flex-col items-center justify-center min-h-[18rem] max-h-screen" // 高度设置为300px
                                >
                                    <h2 className="text-4xl font-bold text-gray-800">{currentCard.word}</h2>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="back"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    className="flex flex-col justify-between min-h-[18rem] max-h[28]"
                                >
                                    {/* 顶部：单词及英标 */}
                                    <div className="flex justify-center items-center space-x-2">
                                        <h3 className="text-2xl font-semibold text-gray-700">{currentCard.word}</h3>
                                        {currentCard.soundmark?.uk?.[0] && (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    playAudio(currentCard.soundmark.uk[1]);
                                                }}
                                                className="text-sm text-blue-500 hover:underline focus:outline-none"
                                            >
                                                {currentCard.soundmark.uk[0]}
                                            </button>
                                        )}
                                        {currentCard.soundmark?.usa?.[0] && (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    playAudio(currentCard.soundmark.usa[1]);
                                                }}
                                                className="text-sm text-green-500 hover:underline focus:outline-none"
                                            >
                                                {currentCard.soundmark.usa[0]}
                                            </button>
                                        )}
                                    </div>

                                    {/* 中部：释义和例句 */}
                                    <div className="text-gray-600 text-sm space-y-2 mt-4 mb-4 flex-grow overflow-y-auto">
                                        <div dangerouslySetInnerHTML={{ __html: currentCard.meanings }}></div>
                                        <div className="mt-2">
                                            {currentCard.eg.map((example, index) => (
                                                <div key={index} className="mb-3">
                                                    <p className="text-gray-800"><strong>例句 {index + 1}:</strong> {example.en}</p>
                                                    <p className="text-gray-500">翻译: {example.ch}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* 底部：回答按钮 */}
                                    <div className="flex justify-between space-x-2 mt-4">
                        <button
                            onClick={(e) => { e.stopPropagation(); handleAnswer('Again', e); }}
                            className="flex-1 py-1.5 px-2 bg-red-500 text-white text-sm rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition-colors duration-200 flex flex-col items-center"
                            title="快捷键: J"
                        >
                            <span>Again</span>
                            {nextReviewTimes?.Again && <span className="text-xs mt-0.5">{formatTime(nextReviewTimes.Again)}</span>}
                        </button>
                        <button
                            onClick={(e) => { e.stopPropagation(); handleAnswer('Hard', e); }}
                            className="flex-1 py-1.5 px-2 bg-yellow-500 text-white text-sm rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition-colors duration-200 flex flex-col items-center"
                            title="快捷键: K"
                        >
                            <span>Hard</span>
                            {nextReviewTimes?.Hard && <span className="text-xs mt-0.5">{formatTime(nextReviewTimes.Hard)}</span>}
                        </button>
                        <button
                            onClick={(e) => { e.stopPropagation(); handleAnswer('Good', e); }}
                            className="flex-1 py-1.5 px-2 bg-green-500 text-white text-sm rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-colors duration-200 flex flex-col items-center"
                            title="快捷键: L"
                        >
                            <span>Good</span>
                            {nextReviewTimes?.Good && <span className="text-xs mt-0.5">{formatTime(nextReviewTimes.Good)}</span>}
                        </button>
                        <button
                            onClick={(e) => { e.stopPropagation(); handleAnswer('Easy', e); }}
                            className="flex-1 py-1.5 px-2 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 flex flex-col items-center"
                            title="快捷键: ;"
                        >
                            <span>Easy</span>
                            {nextReviewTimes?.Easy && <span className="text-xs mt-0.5">{formatTime(nextReviewTimes.Easy)}</span>}
                        </button>
                    </div>
                </motion.div>
                            )}
                        </motion.div>
                    ) : isReviewComplete ? (
                        <motion.div
                            key="congrats"
                            className="w-full max-w-lg bg-white rounded-xl shadow-lg p-6 flex flex-col items-center justify-center space-y-4"
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                            transition={{ duration: 0.3 }}
                        >
                            <h2 className="text-3xl font-bold text-green-600">恭喜！</h2>
                            <p className="text-gray-700 text-center">您已经完成了所有待复习的闪卡。</p>
                            {/* <button
                                onClick={resetReview}
                                className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 focus:outline-none"
                            >
                                重新开始复习
                            </button> */}
                        </motion.div>
                    ) : null}
                </AnimatePresence>
            </div>

            {/* 快捷键说明 */}
            {/* <div className="fixed bottom-0 left-0 right-0 bg-gray-700 bg-opacity-75 text-white py-2 px-4 text-sm text-center">
                快捷键: C: 切换卡片正反面, J: Again, K: Hard, L: Good, ;: Easy
            </div> */}
        </div>
    );
};

export default FlashcardReview;