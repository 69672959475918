import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ArticleEditor from './ArticleEditor';
import { useApi } from '../utils/api';
import Loading from '@/utils/Loading'

const EditArticlePage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const api = useApi();
    const [article, setArticle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            api.get(`/api/articles/${id}`)
                .then(res => {
                    setArticle(res);
                    setIsLoading(false);
                })
                .catch(err => {
                    console.error("Error fetching article:", err);
                    setIsLoading(false);
                });
        } else {
            setArticle({ title: '', content: [] });
            setIsLoading(false);
        }
    }, [id]);

    const handleSave = async (articleData) => {
        try {
            const method = id ? 'put' : 'post';
            const url = id ? `/api/articles/${id}` : '/api/articles';
            const response = await api[method](url, articleData);

            if (response && !response.error) {
                navigate('/articles');
            } else {
                console.error('Failed to save article');
            }
        } catch (error) {
            console.error('Error saving article:', error);
        }
    };

    const handleCancel = () => {
        navigate('/articles');
    };
    if (isLoading) {
        return <Loading/>
    }

    return (
        <div>
            <h1>{id ? 'Edit Article' : 'Create New Article'}</h1>
            {article && (
                <ArticleEditor
                    article={article}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

export default EditArticlePage;