import React, { useRef, useEffect, useState } from 'react';

const WordExplanation = ({ word, onClose, position, wordCache}) => {
    const [wordData, setWordData] = useState(null);
    const boxRef = useRef(null);
    const currentAudioRef = useRef(null);
    const [audioCache, setAudioCache] = useState({});
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        if (wordCache[word]) {
            setWordData(wordCache[word]);
        }
    }, [word, wordCache]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current && !boxRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchend', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchend', handleClickOutside);
        };
    }, [onClose]);

    const playAudio = (audioUrl) => {
        if (currentAudioRef.current) {
            currentAudioRef.current.pause();
            currentAudioRef.current.currentTime = 0;
        }

        if (audioUrl) {
            if (!audioCache[audioUrl]) {
                const audio = new Audio(audioUrl);
                audio.play().catch(error => console.error('Error playing audio:', error));
                setAudioCache(prev => ({ ...prev, [audioUrl]: audio }));
                currentAudioRef.current = audio;
            } else {
                audioCache[audioUrl].play().catch(error => console.error('Error playing cached audio:', error));
                currentAudioRef.current = audioCache[audioUrl];
            }
        }
    };

    useEffect(() => {
        if (wordData && wordData.soundmark && wordData.soundmark.uk) {
            playAudio(wordData.soundmark.uk[1]);
        }
    }, [wordData]);

    if (!wordData) return null;

    const mobileStyle = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: '50vh',
        width: '100%',
        zIndex: 1000,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
    };

    const desktopStyle = {
        position: 'fixed',
        top: `${position.y}px`,
        left: `${position.x}px`,
        width: '350px',
        maxWidth: '350px',
        maxHeight: '500px',
        zIndex: 1000,
        borderRadius: '10px',
    };

    return (
        <div
            ref={boxRef}
            className={`bg-[#b3b3b3] shadow-lg overflow-hidden flex flex-col ${isMobile ? 'rounded-t-lg' : 'rounded-lg'}`}
            style={isMobile ? mobileStyle : desktopStyle}
        >
            <div className="p-4 text-white">
                <div className="flex flex-col items-start">
                    <div className="flex flex-wrap items-center w-full gap-2">
                        <h2 className="text-2xl font-bold">{wordData.word}</h2>
                        <div className="flex items-center flex-wrap gap-2">
                            {wordData.soundmark.uk && (
                                <div className="flex items-center">
                                    <span onClick={() => playAudio(wordData.soundmark.uk[1])} className="cursor-pointer">
                                        {wordData.soundmark.uk[0]}
                                    </span>
                                </div>
                            )}
                            {wordData.soundmark.usa && (
                                <div className="flex items-center">
                                    <span onClick={() => playAudio(wordData.soundmark.usa[1])} className="cursor-pointer">
                                        {wordData.soundmark.usa[0]}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    {wordData.affixes && wordData.affixes.length > 0 && (
                        <div className="text-sm mt-2">
                            {wordData.affixes[0].split('    ').slice(1)}
                        </div>
                    )}
                </div>
            </div>
            <div className="bg-white p-4 custom-scrollbar overflow-y-auto flex-grow" style={{ maxHeight: isMobile ? 'calc(50vh - 60px)' : 'calc(80vh - 60px)' }}>
                <div className="mb-4">
                    <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: wordData.meanings }} />
                </div>
                {wordData.eg && wordData.eg.length > 0 && (
                    <div>
                        <ul className="space-y-3">
                            {wordData.eg.map((example, index) => (
                                <li key={index} className="bg-gray-50 p-3 rounded-lg w-full">
                                    <p className="font-medium text-gray-800 mb-1">
                                        {example.en}
                                        <button onClick={() => playAudio(example.audio)} className="ml-2 text-gray-500 hover:text-gray-700">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    </p>
                                    <p className="text-gray-600">{example.ch}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WordExplanation;