// src/hooks/useImageHandler.js
import { useRef, useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';
import { useApi } from '../utils/api';

const useImageHandler = (sentences, setSentences) => {
    const api = useApi();
    const [showImageMenu, setShowImageMenu] = useState(false);
    const [imageMenuPosition, setImageMenuPosition] = useState({ x: 0, y: 0 });
    const [currentImageIndex, setCurrentImageIndex] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (showImageMenu) {
            const handleClickOutside = (event) => {
                const menuElement = document.querySelector('.fixed.bg-white.border.rounded.shadow-lg');
                if (menuElement && !menuElement.contains(event.target)) {
                    setShowImageMenu(false);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [showImageMenu]);

    const handleImageButtonClick = (index, event) => {
        setCurrentImageIndex(index);
        const rect = event.target.getBoundingClientRect();
        setImageMenuPosition({ x: rect.left, y: rect.bottom });
        setShowImageMenu(true);
    };

    const handleImageMenuSelect = async (source, position) => {
        if (source === 'local') {
            fileInputRef.current.click();
        } else if (source === 'paste') {
            try {
                const clipboardItems = await navigator.clipboard.read();
                for (const item of clipboardItems) {
                    if (item.types.includes('image/png')) {
                        const blob = await item.getType('image/png');
                        const file = new File([blob], "pasted_image.png", { type: "image/png" });
                        const imagePath = await handleImageFile(file, position);
                        if (imagePath) {
                            insertImageIntoSentences(imagePath, position);
                        }
                    }
                }
            } catch (error) {
                console.error('Error pasting image:', error);
            }
        }
        setShowImageMenu(false);
    };

    const insertImageIntoSentences = (imagePath, position) => {
    setSentences(prevSentences => {
        const newSentences = [...prevSentences];
        const newImageSentence = {
            type: 'image',
            src: imagePath,
            width: 300,
            height: 'auto'
        };
        
        if (position === 'below' && currentImageIndex !== null) {
            newSentences.splice(currentImageIndex + 1, 0, newImageSentence);
        } else {
            newSentences.push(newImageSentence);
        }
        
        return newSentences;
    });
};

    const handleImageFile = async (file, position) => {
        console.log('handleImageFile', file, position);
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        const fileExtension = file.name.split('.').pop();
        const newFileName = `${timestamp}.${fileExtension}`;

        // Check the file size and compress if it's larger than 1MB
        let compressedFile = file;
        if (file.size > 1024 * 1024) { // 1MB in bytes
            const options = {
                maxSizeMB: 1,
                useWebWorker: true
            };
            try {
                compressedFile = await imageCompression(file, options);
                console.log(`Original file size: ${file.size / 1024 / 1024} MB`);
                console.log(`Compressed file size: ${compressedFile.size / 1024 / 1024} MB`);
            } catch (error) {
                console.error('Error compressing the image', error);
            }
        }

        const formData = new FormData();
        console.log('formData', formData);
        formData.append('file', compressedFile, newFileName);

        // 检查 FormData 内容
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        try {
            const response = await api.post(`/api/upload/images`, formData);
    
            if (response && response.filename) {
                insertImageIntoSentences(response.filename, position);
                return response.filename;
            } else {
                console.error('Failed to upload image:', response);
                alert('Failed to upload image');
                return null;
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Error uploading image');
            return null;
        }
    };

    const handleImageDelete = async (index) => {
        const filename = sentences[index].src;
        try {
            const response_data = await api.del(`/api/files/${filename}`);
            console.log("handleImageDelete response:", response_data);
            
            if (response_data.success) {
                setSentences(prevSentences => {
                    const newSentences = [...prevSentences];
                    newSentences.splice(index, 1);
                    return newSentences;
                });
            } else {
                alert(response_data.message || 'Failed to delete image');
            }
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleImageResize = (index, size) => {
        setSentences(prevSentences => {
            const newSentences = [...prevSentences];
            if (newSentences[index].type === 'image') {
                newSentences[index] = {
                    ...newSentences[index],
                    width: size.width,
                    height: size.height
                };
            }
            return newSentences;
        });
    };

    return {
        showImageMenu,
        imageMenuPosition,
        fileInputRef,
        handleImageButtonClick,
        handleImageMenuSelect,
        handleImageFile,
        handleImageDelete,
        handleImageResize,
        setShowImageMenu,
    };
};

export default useImageHandler;
