import React, { useState, useEffect, useRef } from 'react';
import { Button } from "@/components/ui/button";
import { IconUpload, IconFileText, IconCloud, IconDeviceFloppy, IconTrash } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../utils/api';

const PDFsPage = () => {
    const api = useApi();
    const [pdfs, setPdfs] = useState([]);
    const [loading, setLoading] = useState(true);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        fetchPDFs();
        loadLocalPDFReferences();
    }, []);

    const loadLocalPDFReferences = () => {
        const localPDFRefs = JSON.parse(localStorage.getItem('localPDFRefs') || '[]');
        setPdfs(prevPdfs => {
            const newLocalRefs = localPDFRefs.filter(localRef => 
                !prevPdfs.some(pdf => pdf._id === localRef.id)
            );
            return [...prevPdfs, ...newLocalRefs];
        });
    };

    const fetchPDFs = async () => {
        try {
            setLoading(true);
            const data = await api.get(`/api/files/list/pdfs`);
            setPdfs(prevPdfs => {
                const serverPDFs = data.map(pdf => ({ ...pdf, isLocal: false }));
                const localPDFs = prevPdfs.filter(pdf => 
                    pdf.isLocal && !serverPDFs.some(serverPdf => serverPdf.filename === pdf.filename)
                );
                return [...serverPDFs, ...localPDFs];
            });
        } catch (error) {
            console.error('Error fetching PDFs:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUploadPDF = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const isServerUpload = window.confirm("Do you want to upload this PDF to the server? Click 'OK' to upload, or 'Cancel' to keep it local.");
            if (isServerUpload) {
                await uploadToServer(file);
            } else {
                saveLocalReference(file);
            }
        }
    };

    const uploadToServer = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const data = await api.post(`/api/upload/pdfs`, formData);

            if (data.filename) {
                fetchPDFs();
            } else {
                console.error('Failed to upload PDF');
            }
        } catch (error) {
            console.error('Error uploading PDF:', error);
        }
    };

    const saveLocalReference = (file) => {
        const newPDFRef = {
            id: Date.now(),
            filename: file.name,
            path: URL.createObjectURL(file),
            isLocal: true
        };
        setPdfs(prevPdfs => [...prevPdfs, newPDFRef]);
        const localPDFRefs = JSON.parse(localStorage.getItem('localPDFRefs') || '[]');
        localPDFRefs.push(newPDFRef);
        localStorage.setItem('localPDFRefs', JSON.stringify(localPDFRefs));
    };

    const handleDelete = async (pdf) => {
        if (pdf.isLocal) {
            const updatedLocalPDFs = pdfs.filter(p => p.id !== pdf._id);
            setPdfs(updatedLocalPDFs);
            localStorage.setItem('localPDFRefs', JSON.stringify(updatedLocalPDFs.filter(p => p.isLocal)));
        } else {
            try {
                const url = `/api/files/${pdf.filename}`;
                const data = await api.del(url);
                if (data.success) {
                    fetchPDFs();
                } else {
                    console.error(`Failed to delete PDF`);
                }
            } catch (error) {
                console.error(`Error deleting PDF:`, error);
            }
        }
    };

    const handleReadPDF = (pdf) => {
        navigate(`/pdfs/${pdf._id}`, { state: { fileId: pdf._id } });
    };

    if (loading) {
        return <div>Loading PDFs...</div>;
    }

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">PDF文档</h1>
                <input
                    type="file"
                    accept=".pdf"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
                <Button onClick={handleUploadPDF}>
                    <IconUpload className="mr-2 h-4 w-4" /> 上传PDF
                </Button>
            </div>
            <ul className="space-y-2">
                {pdfs.map((pdf, index) => (
                    <li key={index} className="flex justify-between items-center p-2 bg-gray-100 rounded">
                        <div className="flex items-center">
                            <span>{pdf.filename}</span>
                        </div>
                        <div className="flex items-center">
                            <Button variant="ghost" size="sm" onClick={() => handleReadPDF(pdf)} className="mr-2">
                                <IconFileText className="mr-1 h-4 w-4" /> 阅读
                            </Button>
                            <Button variant="ghost" size="sm" onClick={() => handleDelete(pdf)} className="mr-2">
                                <IconTrash className="mr-1 h-4 w-4" /> 删除
                            </Button>
                            {pdf.isLocal ? (
                                <IconDeviceFloppy className="h-4 w-4 text-gray-500" />
                            ) : (
                                <IconCloud className="h-4 w-4 text-blue-500" />
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PDFsPage;