import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useApi } from '../../utils/api';

const EmailVerificationForm = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const api = useApi();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      await api.post('/api/verify-email', { email, code: verificationCode });
      setIsLoading(false);
      setError('');
      setTimeout(() => {
        navigate('/login', { state: { message: '邮箱验证成功，请登录' } });
      }, 1500);
    } catch (err) {
      setError('验证失败，请重试');
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    setError('');
    setIsLoading(true);
    try {
      await api.post('/api/resend-verification', { email });
      setError('新的验证码已发送到您的邮箱');
    } catch (err) {
      setError('发送验证码失败，请稍后重试');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-[20rem] p-6 bg-white shadow-md rounded">
        <h2 className="text-2xl font-semibold text-center text-gray-700">验证您的邮箱</h2>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mt-4">
            <Input
              type="text"
              placeholder="验证码"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
              className="w-full"
              disabled={isLoading}
            />
          </div>
          <Button type="submit" className="w-full mt-6 bg-[#5972aa] text-white hover:bg-[#4b68a9]" disabled={isLoading}>
            {isLoading ? '验证中...' : '验证'}
          </Button>
        </form>
        {error && <p className="text-red-500 text-sm mt-4 text-center">{error}</p>}
        <div className="text-center mt-6">
          <button
            onClick={handleResendCode}
            className="text-[#5972aa] hover:underline"
            disabled={isLoading}
          >
            重新发送验证码
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationForm;