import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { IconUpload, IconBook, IconCloud, IconDeviceFloppy, IconTrash } from '@tabler/icons-react';
import { useApi } from '../utils/api';

const EPUBsPage = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [epubs, setEpubs] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
        fetchEPUBs();
        loadLocalEPUBReferences();
    }, []);

    const fetchEPUBs = async () => {
        try {
            const response = await api.get(`/api/files/epubs`);
            const data = await response.json();
            setEpubs(prevEpubs => {
                const serverEPUBs = data.map(epub => ({ ...epub, isLocal: false }));
                return [...serverEPUBs, ...prevEpubs.filter(epub => epub.isLocal)];
            });
        } catch (error) {
            console.error('Error fetching EPUBs:', error);
        }
    };

    const loadLocalEPUBReferences = () => {
        const localEPUBRefs = JSON.parse(localStorage.getItem('localEPUBRefs') || '[]');
        setEpubs(prevEpubs => [...prevEpubs, ...localEPUBRefs]);
    };

    const handleUploadEPUB = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // const isServerUpload = window.confirm("Do you want to upload this EPUB to the server? Click 'OK' to upload, or 'Cancel' to keep it local.");
            const isServerUpload = 1;
            if (isServerUpload) {
                await uploadToServer(file);
            } else {
                saveLocalReference(file);
            }
        }
    };

    const uploadToServer = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await api.post(`/api/upload/epubs`, formData);
            if (response.ok) {
                fetchEPUBs();
            } else {
                console.error('Failed to upload EPUB');
            }
        } catch (error) {
            console.error('Error uploading EPUB:', error);
        }
    };

    const saveLocalReference = (file) => {
        const newEPUBRef = {
            id: Date.now(),
            filename: file.name,
            path: URL.createObjectURL(file),
            isLocal: true
        };
        setEpubs(prevEpubs => [...prevEpubs, newEPUBRef]);
        const localEPUBRefs = JSON.parse(localStorage.getItem('localEPUBRefs') || '[]');
        localEPUBRefs.push(newEPUBRef);
        localStorage.setItem('localEPUBRefs', JSON.stringify(localEPUBRefs));
    };

    const handleReadEPUB = (epub) => {
        navigate(`/epubs/${epub._id}`, { state: { file: epub.isLocal ? epub.path : `${epub.path}` } });
    };

    const handleDelete = async (epub) => {
        if (epub.isLocal) {
            // Delete local EPUB reference
            const updatedLocalEPUBs = epubs.filter(e => e.id !== epub.id);
            setEpubs(updatedLocalEPUBs);
            localStorage.setItem('localEPUBRefs', JSON.stringify(updatedLocalEPUBs.filter(e => e.isLocal)));
        } else {
            // Delete server EPUB
            try {
                const url = `/api/files/${epub.filename}`;
                const response = await api.del(url);
                if (response.ok) {
                    fetchEPUBs();
                } else {
                    console.error(`Failed to delete EPUB`);
                }
            } catch (error) {
                console.error(`Error deleting EPUB:`, error);
            }
        }
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">EPUB文档</h1>
                <input
                    type="file"
                    accept=".epub"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
                <Button onClick={handleUploadEPUB}>
                    <IconUpload className="mr-2 h-4 w-4" /> 上传EPUB
                </Button>
            </div>
            <ul className="space-y-2">
                {epubs.map((epub, index) => (
                    <li key={index} className="flex justify-between items-center p-2 bg-gray-100 rounded">
                        <div className="flex items-center">
                            <span>{epub.filename}</span>
                        </div>
                        <div className="flex items-center">
                            <Button variant="ghost" size="sm" onClick={() => handleReadEPUB(epub)} className="mr-2">
                                <IconBook className="mr-1 h-4 w-4" /> 阅读
                            </Button>
                            <Button variant="ghost" size="sm" onClick={() => handleDelete(epub)} className="mr-2">
                                <IconTrash className="mr-1 h-4 w-4" /> 删除
                            </Button>
                            {epub.isLocal ? (
                                <IconDeviceFloppy className="h-4 w-4 text-gray-500" />
                            ) : (
                                <IconCloud className="h-4 w-4 text-blue-500" />
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default EPUBsPage;