import React, { useState, useRef, useEffect } from 'react';
import { Search } from 'lucide-react';
import { useApi } from '@/utils/api';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [wordData, setWordData] = useState(null);
    const [error, setError] = useState('');
    const [showResults, setShowResults] = useState(false);
    const api = useApi();
    const searchBarRef = useRef(null);

    const handleSearch = async (e) => {
        e.preventDefault();
        setError('');
        setShowResults(true);
        if (!searchTerm.trim()) {
            setError('请输入要查询的单词');
            return;
        }
        try {
            const data = await api.get(`/api/word/${searchTerm.trim()}`);
            setWordData(data);
        } catch (error) {
            console.error('Error fetching word data:', error);
            setError('未找到该单词的信息');
            setWordData(null);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={searchBarRef} className="relative w-full max-w-md mx-auto">
            <form onSubmit={handleSearch} className="flex items-center">
                <div className="relative w-full">
                    <input
                        type="text"
                        className="w-full border border-gray-300 rounded-full h-10 pl-4 pr-12 focus:outline-none focus:ring-2 focus:ring-blue-300"
                        placeholder="输入单词"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button
                        type="submit"
                        className="absolute right-0 top-0 h-full px-3 text-gray-600 hover:text-gray-800 focus:outline-none"
                    >
                        <Search size={20} />
                    </button>
                </div>
            </form>
            {showResults && (error || wordData) && (
                <div className="absolute top-full mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
                    {error ? (
                        <p className="text-red-500 text-sm">{error}</p>
                    ) : wordData ? (
                        <>
                            <h2 className="text-xl font-bold">{wordData.word}</h2>
                            <p className="mt-2">{wordData.meanings}</p>
                            {/* 根据需要展示更多信息 */}
                        </>
                    ) : (
                        <p className="text-gray-500">无记录</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchBar;