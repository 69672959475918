// src/utils/positionUtils.js
export const calculatePosition = (rect) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const tooltipWidth = 400; // 假设悬浮框宽度为400px
    const tooltipHeight = 500; // 假设悬浮框高度为500px
    console.log(`rect.right: ${rect.right}, rect.top: ${rect.top}`)
    let x = rect.right + window.scrollX + 5;
    let y = rect.top + 20;

    // 检查右边界
    if (x + tooltipWidth > windowWidth) {
        x = rect.left - tooltipWidth + window.scrollX;
    }

    // 检查下边界
    if (y + tooltipHeight > windowHeight) {
        y = windowHeight - tooltipHeight - 5;
    }

    return { x, y };
};
