import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IconPhoto, IconEye, IconEyeOff, IconTrash } from '@tabler/icons-react';
import { Resizable } from 're-resizable';
import ImageMenu from './ImageMenu';
import WordExplanation from '@/components/WordExplanation';
import useImageHandler from '@/hooks/useImageHandler';
import useWordHandler from '@/hooks/useWordHandler';
import FastClick from 'fastclick';
import { useApi } from '../utils/api';

const ArticleEditor = ({ article, onSave, onCancel }) => {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [title, setTitle] = useState('');
    const [sentences, setSentences] = useState([]);
    const [showTranslation, setShowTranslation] = useState({});
    const previousArticleId = useRef(null);

    const loadArticleContent = useCallback(async () => {
        if (article._id && article._id !== previousArticleId.current ) {
            setIsLoading(true);
            setError(null);
            setSentences([]);
            setShowTranslation({});

            try {
                if (!article.content) {
                    const res = await api.get(`/api/articles/${article._id}`);
                    if (res.content) {
                        setSentences(res.content);
                    } else {
                        throw new Error('Article content not found');
                    }
                } else {
                    setSentences(article.content);
                }

                setShowTranslation(
                    (article.content || []).reduce((acc, _, index) => {
                        acc[index] = true;
                        return acc;
                    }, {})
                );
                setTitle(article.title || '');
                previousArticleId.current = article._id;
            } catch (err) {
                console.error("Error fetching article content:", err);
                setError("Failed to load article content. Please try again.");
            } finally {
                setIsLoading(false);
            }
        }
    }, [article]);

    useEffect(() => {
        loadArticleContent();
    }, [loadArticleContent]);

    useEffect(() => {
        FastClick.attach(document.body);
    }, []);

    const textareaRef = useRef(null);

    const {
        showImageMenu,
        imageMenuPosition,
        fileInputRef,
        handleImageButtonClick,
        handleImageMenuSelect,
        handleImageFile,
        handleImageDelete,
        handleImageResize,
    } = useImageHandler(sentences, setSentences);

    const {
        selectedWord,
        wordCache,
        showExplanation,
        position,
        handleWordClick,
        setShowExplanation,
    } = useWordHandler();

    const [hoveredLine, setHoveredLine] = useState(null);

    if (isLoading) {
        return <div>Loading article content...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleTextChange = (e) => {
        const newText = e.target.value;
        const newSentences = newText.split('\n').map(line => {
            if (line.trim().startsWith('![image](') && line.trim().endsWith(')')) {
                const match = line.match(/\((.*?)\)/);
                if (match) {
                    return {
                        type: 'image',
                        src: match[1],
                        width: 300,
                        height: 'auto'
                    };
                }
            }
            return {
                type: 'text',
                text: line,
                showTranslation: true,
            };
        });

        setSentences(newSentences);
        // 确保新创建的段落的 `showTranslation` 状态为 true
        const newShowTranslation = newSentences.reduce((acc, _, index) => {
            acc[index] = true; // 默认所有段落展示
            return acc;
        }, {});
        setShowTranslation(newShowTranslation);
    };

    const toggleTranslation = (index) => {
        setShowTranslation(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleSubmit = async () => {
        const articleData = {
            title,
            content: sentences,
        };
    
        // 检查文档是否为空
        if (title.trim() === '' && sentences.length === 0) {
            alert('文章内容为空，无法保存。');
            return;
        }
    
        try {
            if (article._id) {
                // 更新操作
                // 检查文档是否有变更
                const hasChanged = title !== article.title || 
                                   JSON.stringify(sentences) !== JSON.stringify(article.content);
                
                if (!hasChanged) {
                    console.log("文档未变更，跳过更新操作");
                    onSave(article); // 直接调用 onSave，不进行 API 调用
                    return;
                }
    
                const data = await api.put(`/api/articles/${article._id}`, articleData);
                console.log("更新文章", data);
                if (data && !data.error) {
                    onSave(data);
                } else {
                    alert('更新文章时出错：' + (data.message || '未知错误'));
                }
            } else {
                // 添加操作
                const data = await api.post('/api/articles', articleData);
                console.log("添加文章", data);
                if (data && !data.error) {
                    onSave(data);
                } else {
                    alert('添加文章时出错：' + (data.message || '未知错误'));
                }
            }
        } catch (error) {
            console.error("Error saving article:", error);
            alert('保存文章时出错：' + (error.message || '未知错误'));
        }
    };
    
    const handlePaste = async (e) => {
        e.preventDefault();
        const items = e.clipboardData.items;
        const textarea = textareaRef.current;
        const cursorPosition = textarea.selectionStart;
        const text = textarea.value;

        for (const item of items) {
            if (item.type.indexOf("image") !== -1) {
                const file = item.getAsFile();
                const imagePath = await handleImageFile(file, 'below');

                // 检查光标前面是否为换行符
                const isNewLine = cursorPosition === 0 || text[cursorPosition - 1] === '\n';

                // 根据是否在行首决定是否添加换行符
                const imageText = isNewLine ? `![image](${imagePath})` : `\n![image](${imagePath})`;

                const newText = text.slice(0, cursorPosition) + imageText + text.slice(cursorPosition);

                textarea.value = newText;
                handleTextChange({ target: { value: newText } });

                // 设置光标位置在插入的image之后
                const newPosition = cursorPosition + imageText.length;
                textarea.setSelectionRange(newPosition, newPosition);

                // 插入image后跳出循环，确保只插入一张image
                break;
            } else if (item.type === 'text/plain') {
                // 文本粘贴的处理保持不变
                item.getAsString((pastedText) => {
                    const newText = text.slice(0, cursorPosition) + pastedText + text.slice(cursorPosition);
                    textarea.value = newText;
                    handleTextChange({ target: { value: newText } });
                    textarea.setSelectionRange(cursorPosition + pastedText.length, cursorPosition + pastedText.length);
                });
            }
        }
    };

    const removeEmptyLines = () => {
        const newSentences = sentences.filter(sentence =>
            sentence.type !== 'text' || (sentence.text && sentence.text.trim() !== '')
        );
        setSentences(newSentences);

        // 更新textarea的内容
        if (textareaRef.current) {
            const newText = newSentences.map(s => s.type === 'text' ? s.text : `![image](${s.src})`).join('\n');
            textareaRef.current.value = newText;
            handleTextChange({ target: { value: newText } });
        }
    };

    const renderSentences = () => {
        return sentences.map((sentence, index) => (
            <React.Fragment key={`sentence-${index}`}>
                {sentence.type === 'image' ? (
                    <div className='image-wrapper' key={`image-${index}`}>
                        <div className="group image-container relative inline-block mb-2">
                            <Resizable
                                key={`resizable-${index}`}
                                size={{ width: sentence.width, height: sentence.height }}
                                onResizeStop={(e, direction, ref, d) => {
                                    handleImageResize(index, {
                                        width: sentence.width + d.width,
                                        height: sentence.height + d.height
                                    });
                                }}
                                minWidth={100}
                                minHeight={100}
                                maxWidth={window.innerWidth - 50}
                                maxHeight={window.innerHeight - 200}
                            >
                                <img
                                    key={`img-${index}`}
                                    src={`${process.env.REACT_APP_API_HOST}/api/files/serve/${sentence.src}`}
                                    alt={`Inserted image ${index}`}
                                    className="image block w-full h-auto"
                                />
                            </Resizable>
                            <span
                                key={`delete-${index}`}
                                className="delete-icon absolute top-2.5 right-2.5 bg-red-500 bg-opacity-70 text-white p-1 rounded-full cursor-pointer group-hover:block"
                                onClick={() => handleImageDelete(index)}
                            >
                                <IconTrash />
                            </span>
                        </div>
                    </div>
                ) : (
                    <div
                        key={`text-${index}`}
                        className="group relative mb-2 flex items-center"
                        onMouseEnter={() => setHoveredLine(index)}
                        onMouseLeave={() => setHoveredLine(null)}
                    >
                        {sentence.text && sentence.text.trim() && (
                            <button
                                key={`toggle-${index}`}
                                onClick={() => toggleTranslation(index)}
                                className="mr-2 p-1 text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                                {showTranslation[index] ? <IconEye size={16} opacity={0.2} /> : <IconEyeOff size={16} />}
                            </button>
                        )}
                        <pre className="mr-10 font-sans whitespace-pre-wrap break-words flex-grow">
                            <span style={{ display: showTranslation[index] ? 'inline' : 'none' }}>
                                {typeof sentence.text === 'string' && sentence.text.split(/(\S+)/).map((part, partIndex) => (
                                    <React.Fragment key={`part-${index}-${partIndex}`}>
                                        {part.trim() ? (
                                            <span
                                                className="cursor-pointer hover:bg-blue-100 rounded"
                                                onClick={(e) => handleWordClick(part, e)}
                                            >
                                                {part}
                                            </span>
                                        ) : part}
                                    </React.Fragment>
                                ))}
                            </span>
                            {!showTranslation[index] && <span key={`empty-${index}`}>&nbsp;</span>}
                        </pre>
                        {hoveredLine === index && (
                            <div key={`hover-buttons-${index}`} className="absolute right-0 flex space-x-2">
                                <button
                                    key={`insert-image-${index}`}
                                    onClick={(e) => handleImageButtonClick(index, e)}
                                    className="p-1 text-gray-500 hover:text-gray-700 focus:outline-none"
                                    title="Insert image"
                                >
                                    <IconPhoto />
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </React.Fragment>
        ));
    };

    return (
        <div className="max-w-[1000px] mx-auto w-full p-4">
            <input
                type="text"
                className="w-full p-2 border rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="文章标题"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
                ref={textareaRef}
                className="w-full p-2 border rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 font-mono"
                rows="10"
                placeholder="在此输入文章内容..."
                value={sentences.map(s => s.type === 'text' ? s.text : `![image](${s.src})`).join('\n')}
                onChange={handleTextChange}
                onPaste={handlePaste}
            />

            <div className="mb-4 text-justify w-full">
                {renderSentences()}
            </div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={(e) => {
                    if (e.target.files[0]) {
                        handleImageFile(e.target.files[0], 'below');
                    }
                }}
            />
            {showExplanation && selectedWord && (
                <WordExplanation
                    word={selectedWord}
                    onClose={() => setShowExplanation(false)}
                    position={position}
                    wordCache={wordCache}
                />
            )}
            {showImageMenu && (
                <ImageMenu
                    position={imageMenuPosition}
                    onSelect={handleImageMenuSelect}
                />
            )}
            <div className="flex justify-end space-x-4 mt-4">
                <button
                    className="bg-yellow-500 text-white p-2 rounded hover:bg-yellow-600 focus:outline-none"
                    onClick={removeEmptyLines}
                >
                    移除空行
                </button>
                <button
                    className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600 focus:outline-none"
                    onClick={onCancel}
                >
                    取消
                </button>
                <button
                    className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none"
                    onClick={handleSubmit}
                >
                    {article._id ? '更新' : '保存'}
                </button>
            </div>
        </div>
    );
};

export default ArticleEditor;