import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Input } from '../ui/input';
import { Button } from '../ui/button';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { login, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
    }
  }, [location]);
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await login(email, password);
      navigate('/');
    } catch (err) {
      console.error('Login error:', err);
      setError('登录失败，请检查您的邮箱和密码或稍后重试');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-[20rem] p-6 bg-white shadow-md rounded">
        <h2 className="text-2xl font-semibold text-center text-gray-700">登录您的账户</h2>
        {message && <p className="text-green-500 text-sm text-center mt-2">{message}</p>}
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mt-4">
            <Input
              type="email"
              placeholder="邮箱"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full"
            />
          </div>
          <div className="mt-4">
            <Input
              type="password"
              placeholder="密码"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full"
            />
          </div>
          <Button type="submit" className="w-full mt-6 bg-[#5972aa] text-white hover:bg-[#4b68a9]" disabled={isLoading}>
            {isLoading ? '登录中...' : '登录'}
          </Button>
        </form>
        {error && <p className="text-red-500 text-sm mt-4 text-center">{error}</p>}
        <div className="flex justify-between mt-6">
          <Link to="/password-reset-request" className="text-sm text-[#5972aa] hover:underline">忘记密码？</Link>
          <Link to="/register" className="text-sm text-[#5972aa] hover:underline">没有账户？注册</Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;